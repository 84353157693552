import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import axios from "axios";
import { useTranslation } from "react-i18next";
const Home = () => {
    const [image, setImage] = useState([])
    const trendingImages = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/public/listTrendingEvents`);
        setImage(response?.data?.events
        )
    }

    useEffect(() => {

        trendingImages()
    }, [])
    const navigate = useNavigate()
    const handleEvents = (item) => {
        const id = item._id;
        navigate("event-details", { state: { id } });
    };
    const [height, setHeight] = useState()
    const [fontSize, setFontSize] = useState("10px")
    useEffect(() => {
        const currentWidth = window.innerWidth
        if (currentWidth >= 1200) {
            setHeight('35em')
            setFontSize('40px')
        } else if (currentWidth >= 750) {
            setHeight('30em')
            setFontSize('30px')

        } else {
            setHeight('25em')
            setFontSize('20px')

        }

    }, [])

    const [isMouseEntered, setIsMouseEntered] = useState(false)
    // const[isMouseLeaved,setIsMouseEntered]=useState(false)


    const autoplay = () => {
        if (isMouseEntered) {

        }
    }


    function handleBook() {
        navigate("/book-ride")
    }

    // translation handling
    const { t } = useTranslation();
    const { home } = t("screen");

    console.log("home book", home?.bookARide);
    

    return (
        <>
            <section className="ms-banner-area p-relative mt-3">

                <div className="container-fluid ms-maw-1710" >

                    <div className=" mx-auto include__bg z-index-1 ms-overlay-1"
                    // onMouseEnter={() => setIsMouseEntered(true)}
                    // onMouseLeave={() => setIsMouseEntered(false)}
                    >


                        <Swiper
                            modules={[Autoplay, Navigation]}
                            spaceBetween={30}
                            speed={4000}
                            centeredSlides={true}

                            autoplay={{ delay: 3000, }}
                            navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',

                            }}
                            className="ms-event-dots"

                        >
                            {image.map((item, index) => {
                                const imgArray = JSON.parse(item?.eventImage);
                                const firstImgCheck = imgArray?.[0];
                                let firstImg = firstImgCheck ? firstImgCheck : "assets/img/function-brand/function-brand-01.png";
                                return (
                                    <SwiperSlide key={item?._id}>
                                        <div className="position-relative overlay-container rounded-lg" style={{  position:"relative" }}>
                                            <img src={firstImg} alt={`Slide ${index + 1}`}  style={{ width: '100%', height: height }} />
                                            <div className="position-absolute w-100 " style={{ bottom: '15%', left:"3%" }}>
                                                <h3 className=" mx-2 section__title homeEventTitle"
                                                    style={{ fontSize: fontSize, marginBottom: "0.25em" }}
                                                    onClick={() => handleEvents(item)} >
                                                    <Link href="/event-details">{item.eventName}</Link>
                                                </h3>
                                                {/* <button
                                                    className='  mx-2 text-white unfill__btn'
                                                    onClick={handleBook} >{home?.bookARide}</button>
                                                <button className=' unfill__btn mx-2 text-white' onClick={() => navigate("/offer-ride")}>{home?.offerArRide}</button> */}
                                            </div>

                                            <div className=' absolute hidden group-hover:block  ' style={{   justifyContent:"center", alignItems : "flex-end", bottom:"10px",  background:"transparent", height:"100%", width: "100%"}}>

                                                <div style={{width:"95%", height:"40%", border:"3px solid green", borderBottomLeftRadius : "30px",borderBottomRightRadius : "30px", borderTop:"none"}}>
                                                <p>hello</p>

                                                </div>
                                            </div>

                                        </div>
                                    </SwiperSlide>

                                )
                            }

                            )}
                        </Swiper>
                        <div className="swiper-button-next" style={{ color: "white" }}></div>
                        <div className="swiper-button-prev" style={{ color: "white" }}></div>
                    </div>
                </div>


            </section>
        </>
    );
};

export default Home;