import React, { createContext, useState, useContext, useEffect } from 'react';

const DirectionContext = createContext(undefined);

const DirectionProvider = ({ children }) => {
  const [direction, setDirection] = useState('ltr');

  useEffect(() => {
    const storedDirection = localStorage.getItem('direction');
    if (storedDirection) {
      setDirection(storedDirection);
      document.documentElement.setAttribute('dir', storedDirection);
    } else {
      localStorage.setItem('direction', 'ltr');
    }
  }, []);

  const toggleDirection = () => {
    const newDirection = direction === 'ltr' ? 'rtl' : 'ltr';
    setDirection(newDirection);
    localStorage.setItem('direction', newDirection);
    document.documentElement.setAttribute('dir', newDirection);
  };

  const contextValue = {
    direction,
    toggleDirection,
  };

  return (
    <DirectionContext.Provider value={contextValue}>
      {children}
    </DirectionContext.Provider>
  );
};

const useDirection = () => {
  const context = useContext(DirectionContext);
  if (!context) {
    throw new Error('useDirection must be used within a DirectionProvider');
  }
  return context;
};

export { DirectionProvider, useDirection };
