import React, { useEffect, useState } from 'react';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import { useNavigate } from 'react-router-dom';
import useScrollToTop from '../../hooks/UseScrollToTop';



import { FaArrowRight } from "react-icons/fa6";


// import car from "../../assets/img/logo/car.jpg";
// import car from "../../assets/img/images/Define the driver profile.png";
import car from "../../assets/img/images/Define the driver profile(2).png";
// import fillForm from "../../assets/img/logo/fillForm2.jpg";
import fillForm from "../../assets/img/images/Draft or publish a trip offer(2).png"
// import startRide from "../../assets/img/logo/startRide.jpg";
import startRide from "../../assets/img/images/Start your trip and enjoy the event(2).png"

import { useTranslation } from 'react-i18next';
import Loader from '../preloander/Loander';







const OfferARide = () => {

    const { t } = useTranslation();
    const { bookARide, offerARide } = t("screen");

    const [loading, setLoading] = useState(true)

    useEffect(() => {

        setTimeout(() => {

            setLoading(false)

        }, 1000);

    }, [])




    useScrollToTop();

    const [activeTab, setActiveTab] = useState('events');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const navigate = useNavigate()
    return (

        <>

            {
                loading ?

                    <Loader />

                    :

                    <div >
                        {/* <HeaderOne /> */}
                        <Breadcrumb title={offerARide?.HowToOfferARide} />
                        {/* <section className="header" >
                <div className="container-fluid" style={{ padding: 0 }}>
                    <div className="">
                        <div>
                            <div
                                className=' h-30'
                            >
                                <div>
                                    <div className='justify-center text-center pt-6'>
                                        <h2 className='text-3xl text-white p-2'>How To OFFER RIDE</h2>
                                    </div>
                                    <div className='flex justify-center items-center py-10'>
                                        <div className='grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-3 ml-10'>
                                            <div className='flex flex-col justify-center items-center'>
                                                <img src={ride1} alt="" style={{ height: "30em" }} />
                                                <p className='text-white text-md text-center'>
                                                    1: First Go To The Ride Section and then Choose the Offer Ride Option
                                                </p>
                                            </div>
                                            <div className='flex flex-col justify-center items-center'>
                                                <img src={ride6} alt="" style={{ height: "30em" }} />
                                                <p className='text-white text-md text-center'>
                                                    2: Add A Vehicle Fill the Data And go to the saved Vehicle
                                                </p>
                                            </div>
                                            <div className='flex flex-col justify-center items-center'>
                                                <img src={ride3} alt="" style={{ height: "30em" }} />
                                                <p className='text-white text-md text-center'>
                                                    3: Choose The Published Vehicle For Offering Ride
                                                </p>
                                            </div>
                                            <div className='flex flex-col justify-center items-center'>
                                                <img src={ride2} alt="" style={{ height: "30em" }} />
                                                <p className='text-white text-md text-center'>
                                                    4: Choose the One Way Trip Or Round Trip Accordingly Fill the data and Then select Draft Or Publish. Published Ride will be Visible to Passenger
                                                </p>
                                            </div>
                                            <div className='flex flex-col justify-center items-center'>
                                                <img src={ride4} alt="" style={{ height: "30em" }} />
                                                <p className='text-white text-md text-center'>
                                                    5: Go To Agenda Section and view your all ride offered And Taken
                                                </p>
                                            </div>
                                            <div className='flex flex-col justify-center items-center'>
                                                <img src={ride5} alt="" style={{ height: "30em" }} />
                                                <p className='text-white text-md text-center'>
                                                    6: After Selecting the ride you can Start The Ride
                                                </p>
                                            </div>
                                            <div className='flex flex-col justify-center items-center'>
                                                <img src={ride7} alt="" style={{ height: "30em" }} />
                                                <p className='text-white text-md text-center'>
                                                    7: Arrive On your Pickup Location and pickup passenger and then start ride
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

                        <section className="header" >
                            <div className="container-fluid d-flex" style={{ padding: "0%" }}>
                                <div style={{ width: "100%" }}>
                                    <div>
                                        <div
                                            className=' h-30'
                                        >
                                            <div>
                                                {/* <div className='justify-center text-center pt-6'>
                                                    <h1 style={{ fontFamily: 'Prompt-Regular, sans-serif',textTransform:"none" }} className='section__title text-3xl text-white p-2'>{offerARide?.HowToOfferARide}</h1>
                                                </div> */}

                                                {/* <div className="container">
                                                    <div className="row d-flex  flex-lg-row flex-column-reverse  align-items-center ">
                                                        <div className=" py-5 col-xl-6 col-md-12 d-flex  justify-content-center img-container">
                                                            <img src={car} alt="" style={{ borderRadius: "12px" }} />
                                                        </div>
                                                        <div className="col-xl-6 col-md-12 d-flex justify-content-center" >
                                                            <div className='p-2' style={{ color: "white", gap: "4px", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "self-start" }}>

                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <h2 style={{ fontSize: "2em", marginBottom: "5px", fontFamily: 'Prompt-Regular, sans-serif' }} className='text-white'>{bookARide?.StepOne}</h2>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <FaArrowRight style={{ marginTop: "4px", width: "30px", }} />
                                                                    <p style={{fontFamily: 'Prompt-Regular, sans-serif'}} className='text-white'>{offerARide?.FirstGoToTheRideSection}</p>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <FaArrowRight style={{ marginTop: "4px", width: "30px", }} />
                                                                    <p style={{fontFamily: 'Prompt-Regular, sans-serif'}} className='text-white'>{offerARide?.AddAVehicleFilltheDataAndgotothesavedVehicle}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row align-items-center ">

                                                        <div className="col-xl-6 col-md-12 d-flex justify-content-center" >
                                                            <div className='p-2' style={{ color: "white", gap: "4px", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "self-start" }}>

                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <h2 style={{ fontSize: "2em", marginBottom: "5px", fontFamily: 'Prompt-Regular, sans-serif' }} className='text-white'>{bookARide?.StepTwo}</h2>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <FaArrowRight style={{ marginTop: "4px", width: "30px", }} />
                                                                    <p style={{fontFamily: 'Prompt-Regular, sans-serif'}} className='text-white'>{offerARide?.ChooseThePublishedVehicleForOfferingRide}</p>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <FaArrowRight style={{ marginTop: "4px", width: "30px", }} />
                                                                    <p style={{fontFamily: 'Prompt-Regular, sans-serif'}} className='text-white'>{offerARide?.ChoosetheOneWayTripOrRoundTrip}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className=" py-5 col-xl-6 col-md-12 d-flex  justify-content-center img-container">

                                                            <img src={fillForm} alt="" style={{ borderRadius: "12px" }} />

                                                        </div>
                                                    </div>


                                                    <div className="row d-flex flex-lg-row flex-column-reverse ">
                                                        <div className=" py-5 col-xl-6 col-md-12 d-flex  justify-content-center img-container">
                                                            <img src={startRide} alt="" style={{ borderRadius: "12px" }} />
                                                        </div>
                                                        <div className="col-xl-6 col-md-12 d-flex align-items-center justify-content-center" >
                                                            <div className='p-2' style={{ color: "white", gap: "4px", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "self-start" }}>

                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <h2 style={{ fontSize: "2em", marginBottom: "5px",fontFamily: 'Prompt-Regular, sans-serif' }} className='text-white'>{bookARide?.StepThree}</h2>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <FaArrowRight style={{ marginTop: "4px", width: "30px", }} />
                                                                    <p style={{fontFamily: 'Prompt-Regular, sans-serif'}} className='text-white'>{offerARide?.GoToAgendaSectionandviewyourallride}</p>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <FaArrowRight style={{ marginTop: "4px", width: "30px", }} />
                                                                    <p style={{fontFamily: 'Prompt-Regular, sans-serif'}} className='text-white'>{offerARide?.AfterSelectingtherideyou}</p>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <FaArrowRight style={{ marginTop: "4px", width: "30px", }} />
                                                                    <p style={{fontFamily: 'Prompt-Regular, sans-serif'}} className='text-white'>{offerARide?.ArriveOnyourPickupLocationandpickup}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div className="container ">

                                                    {/* 1st */}
                                                    <div className="grid md:grid-cols-2 grid-cols-1 justify-around items-center ">
                                                        {/* Image Container */}
                                                        <div className="py-5 md:w-auto w-full d-flex justify-center img-container">
                                                            <img src={car} alt="Search Ride" className="rounded-xl" />
                                                        </div>

                                                        {/* Text Content */}
                                                        <div className="md:w-auto w-full d-flex justify-center">
                                                            <div className="p-2 text-white flex flex-col gap-1 justify-start items-start">
                                                                <div className="flex gap-1 items-start">
                                                                    <h2 className="section__subtitle text-3xl mb-1 font-sans text-white" style={{ fontFamily: 'Oregano-Italic, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.Definethedriverprofile}
                                                                    </h2>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                <span className=" mr-2 font-bold text-lg">1.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                        {offerARide?.addtothebasicprofilethevehicledetails}
                                                                    </p>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                <span className=" mr-2 font-bold text-lg">2.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                        {offerARide?.chooseyourtrippreferencesandlimitations}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    {/* 2st */}
                                                    <div className="grid md:grid-cols-2 grid-cols-1  items-center ">
                                                        <div className=" md:hidden py-5 w-full flex justify-center img-container">
                                                            <img src={fillForm} alt="Route Detail" className="rounded-xl" />
                                                        </div>
                                                        {/* Text Content */}
                                                        <div className=" hidden w-full md:flex justify-center">
                                                            <div className="p-2 text-white flex flex-col gap-1 justify-start items-start">
                                                                <div className="flex gap-1 items-start">
                                                                    <h2 className="section__subtitle text-3xl mb-1 font-sans text-white" style={{ fontFamily: 'Oregano-Italic, sans-serif', textTransform:"none" }}>
                                                                    {bookARide?.Draftorpublishatripoffer}
                                                                    </h2>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                <span className=" mr-2 font-bold text-lg">1.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                    {offerARide?.chooseaonewayorreturntrip}
                                                                    </p>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                <span className=" mr-2 font-bold text-lg">2.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                    {offerARide?.Selectthedeparturepointandtheeventtoreach}
                                                                    </p>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                <span className=" mr-2 font-bold text-lg">3.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                    {offerARide?.Addanystopover}
                                                                    </p>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                <span className=" mr-2 font-bold text-lg">4.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                    {offerARide?.Definethetimeschedule}
                                                                    </p>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                <span className=" mr-2 font-bold text-lg">5.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                    {offerARide?.Decidehowmanyseatstoofferandthepriceperseat}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Image Container */}
                                                        <div className=" py-5 w-full hidden md:flex justify-center img-container">
                                                            <img src={fillForm} alt="Route Detail" className="rounded-xl" />
                                                        </div>

                                                        <div className=" md:hidden w-full flex justify-center">
                                                            <div className="p-2 text-white flex flex-col gap-1 justify-start items-start">
                                                                <div className="flex gap-1 items-start">
                                                                    <h2 className="section__subtitle text-3xl mb-1 font-sans text-white" style={{ fontFamily: 'Oregano-Italic, sans-serif', textTransform:"none" }}>
                                                                    {bookARide?.Draftorpublishatripoffer}
                                                                    </h2>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                <span className=" mr-2 font-bold text-lg">1.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                    {offerARide?.chooseaonewayorreturntrip}
                                                                    </p>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                <span className=" mr-2 font-bold text-lg">2.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                    {offerARide?.Selectthedeparturepointandtheeventtoreach}
                                                                    </p>
                                                                </div>
                                                                <div className="flex gap-1 items-start">
                                                                <span className=" mr-2 font-bold text-lg">3.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                    {offerARide?.Addanystopover}
                                                                    </p>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                <span className=" mr-2 font-bold text-lg">4.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                    {offerARide?.Definethetimeschedule}
                                                                    </p>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                <span className=" mr-2 font-bold text-lg">5.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                    {offerARide?.Decidehowmanyseatstoofferandthepriceperseat}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* 3rd */}
                                                    <div className="grid md:grid-cols-2 grid-cols-1 justify-around items-center ">
                                                        {/* Image Container */}
                                                        <div className="py-5 md:w-auto w-full d-flex justify-center img-container">
                                                            <img src={startRide} alt="Search Ride" className="rounded-xl" />
                                                        </div>

                                                        {/* Text Content */}
                                                        <div className="md:w-auto w-full d-flex justify-center">
                                                            <div className="p-2 text-white flex flex-col gap-1 justify-start items-start">
                                                                <div className="flex gap-1 items-start">
                                                                    <h2 className="section__subtitle text-2xl lg:text-3xl mb-1 font-sans text-white" style={{ fontFamily: 'Oregano-Italic, sans-serif', textTransform:"none" }}>
                                                                    {bookARide?.Startyourtripandenjoytheevent}
                                                                    </h2>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                <span className=" mr-2 font-bold text-lg">1.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                    {offerARide?.opentheofferedridesinyouragenda}
                                                                    </p>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                <span className=" mr-2 font-bold text-lg">2.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                    {offerARide?.checkoutanynotificationfromthepassengers}
                                                                    </p>
                                                                </div>
                                                                <div className="flex gap-1 items-start">
                                                                <span className=" mr-2 font-bold text-lg">3.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                    {offerARide?.updatethedetailsforanychange}
                                                                    </p>
                                                                </div>
                                                                <div className="flex gap-1 items-start">
                                                                <span className=" mr-2 font-bold text-lg">4.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                    {offerARide?.reachthepickuppointsattheagreedtime}
                                                                    </p>
                                                                </div>
                                                                <div className="flex gap-1 items-start">
                                                                <span className=" mr-2 font-bold text-lg">5.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                    {offerARide?.atthearrivalleaveafeedbacktothepassengers}
                                                                    </p>
                                                                </div>
                                                                <div className="flex gap-1 items-start">
                                                                <span className=" mr-2 font-bold text-lg">6.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                    {offerARide?.controlyourwallethasbeencharged}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                   
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>


                    </div>


            }

        </>

    );
};

export default OfferARide;

