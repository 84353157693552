import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FcLike } from "react-icons/fc";
// import popular_band_fuction_data from '../../data/popular-band-function-data';
import GetRatting from '../../hooks/GetRating';
import { imageLoader } from '../../hooks/ImageLoader';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FaLocationDot } from "react-icons/fa6";
import { PhotoProvider } from 'react-photo-view';
import { useTranslation } from 'react-i18next';
const MostPopularEvent = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/public/listPopularEvents`);
                // const newData = response?.data?.events.length > 4 ? response.data?.events?.splice(0,4) : response?.data;
                // console.log("newData",newData);

                const events = response?.data?.events;

                let newEvents = [];

                if (events?.length > 4) {

                    const cutEvent = events?.splice(0, 4);
                    newEvents = cutEvent;

                } else {
                    newEvents = events
                }

                // console.log("events",events);


                setData(newEvents);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const handleEvents = (item) => {
        const id = item._id;
        navigate("event-details", { state: { id } });
        console.log("clicked", id)
    };

     // translation handling
     const { t } = useTranslation();
     const { home } = t("screen");
 

    return (
        <>
            <section style={{marginBottom:"20px"}} className="ms-fun-brand ms-bg-2 pb-90 pt-20" id='popularEvent'>
                <div className="container">
                    <div className="row justify-content-center bdFadeUp">
                        <div className="col-xl-7">
                            <div className="section__title-wrapper mb-65 text-center bd-title-anim">
                                <span className="section__subtitle" style={{ fontFamily: 'Oregano-Italic, sans-serif' }}>{home?.eventThisMonth}</span>
                                {/* <h2 className="section__title">
                                    The Most <span className="animated-underline active"> popular Event</span>
                                    This Month
                                </h2> */}
                                 <h2 className="section__title" style={{ fontFamily: 'Prompt-Regular, sans-serif' }}>
                                    {home?.theMostPopularEventThisMonth}
                                </h2>
                            </div>
                        </div>
                    </div>

                    {/* <div className="ms-fun-brand-wrap bdFadeUp">
                        {data && data?.length > 0 && data?.map((item) => {
                            const imgArray = JSON.parse(item?.eventImage);
                            const firstImgCheck = imgArray?.[0];
                            const firstImg = firstImgCheck ? firstImgCheck : "assets/img/function-brand/function-brand-01.png";

                            return (
                                
                                <div className="trending-item" style={{ cursor: "pointer" }} key={item._id} onClick={() => handleEvents(item)}>
                                    <div className="trending__thumb" style={{ backgroundImage: `url(${firstImg})` }}></div>
                                    <div className="trending__info">
                                        <div style={{ width: "100%", marginTop: "10px", display: "flex", justifyContent: "flex-start" }}>
                                            <span style={{ fontSize: "25px", color: "orange" }}>{(item.eventName)}</span>
                                        </div>
                                        <div className="trending__content" style={{ height: "10%", marginTop: "15px" }}>
                                            <div className="ms-fun-brand-bottom">
                                                <div className="ms-fun-brand-location" >
                                                    <Link href="https://www.google.com/maps" target="_blank">
                                                       
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <FaLocationDot />
                                                            <span style={{ marginLeft: '8px', fontSize: '1.5rem' }}>{item.city}</span>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="ms-fun-brand-rating">
                                                    <div style={{ display: "flex" }}>
                                                        <span>
                                                            <FcLike size={30} />
                                                        </span>
                                                        <span style={{ marginTop: "0.2em", marginLeft: "0.3em" }}>{item.confirmedCount}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div> */}

                    <div className="row bdFadeUp">
                            <div className="trending-grid">
                                <PhotoProvider
                                    speed={() => 800}
                                    easing={(type) => type === 2 ? "cubic-bezier(0.36, 0, 0.66, -0.56)" : "cubic-bezier(0.34, 1.56, 0.64, 1)"}
                                >
                                    { data && data?.splice(0, 3)?.map((item, index) => {
                                        const imgArray = JSON.parse(item?.eventImage);
                                        const firstImgCheck = imgArray?.[0];
                                        let firstImg = firstImgCheck ? firstImgCheck : "assets/img/function-brand/function-brand-01.png";

                                        return (
                                            <div className="trending-item" style={{ cursor: "pointer" }} key={item._id} onClick={() => handleEvents(item)}>
                                                <div className="trending__thumb" style={{ backgroundImage: `url(${firstImg})` }}></div>
                                                <div className="trending__info">
                                                    
                                                    <div className="trending__price">
                                                        <span>Trending</span>
                                                    </div>
                                                    <div style={{ width: "100%", marginTop: "10px", display: "flex", justifyContent: "flex-start" }}>
                                                        <span style={{ fontSize: "25px", color: "white" }}>{(item.eventName)}</span>
                                                    </div>

                                                    <div className="trending__content" style={{ height: "10%", width:"90%", marginTop: "15px" }}>
                                                        {/* <h4 onClick={() => handleClick(item)}>{item.eventName}</h4> */}
                                                        <p style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", height: "50px" }} dangerouslySetInnerHTML={{ __html: item.eventDescription }}></p>
                                                        <p>Read more</p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </PhotoProvider>
                            </div>
                        </div>
                </div>
            </section>
        </>
    );
};

export default MostPopularEvent;
