import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
export const AppContext = createContext(undefined);

const AppProvider = ({ children }) => {
  const location = useLocation()
  const pathName = location.pathname;
  const [niceSelectData, setNiceSelectData] = useState("no-data");

  // Update niceSelectData when the pathname changes
  useEffect(() => {
    setNiceSelectData("no-data");
  }, [pathName]);

  const contextValue = {
    niceSelectData,
    setNiceSelectData,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export default AppProvider;
