import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import { imageLoader } from "../../hooks/ImageLoader";
import NewsImg from '../../assets/img/news/news-07.jpg';
import ContactForm from "../../form/contact-form";
import ContactMapArea from "../contact/ContactMapArea";
import PartnerAreaMain from "../common/PartnerArea/PartnerAreaMain";
import { Link } from "react-router-dom";
import { FiPhoneCall } from "react-icons/fi";
import useScrollToTop from "../../hooks/UseScrollToTop";
import OrganiserForm from "../../form/organiser-form";
import { useEffect, useState } from "react";
import axios from "axios";
import DOMPurify from 'dompurify';
import Loader from "../preloander/Loander";


const TermsAndCondition = () => {
    useScrollToTop()


    const [content, setContent] = useState("");
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        setTimeout(() => {

            setLoading(false)

        }, 1000);

    }, [])



    useEffect(() => {
        getTermsAndCondition();
    }, []);


    async function getTermsAndCondition() {


        try {

            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/client/getTermsAndConditions`);


            // console.log("content", response?.data?.data?.content);

            const sanitizedContent = DOMPurify.sanitize(response?.data?.data?.content); // Ensure you access the correct path
            setContent(sanitizedContent);


        } catch (error) {

            console.log("error while fetching terms and condition");

        }


    }


    return (
        <>

            {
                loading ?
                    <Loader />
                    :
                    <div>
                        <Breadcrumb title="Terms & Condition" />
                        <section className="ms-contact-area pb-30 pt-130">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="ms-contact-wrap ms-contact-space mb-30 ms-bg-2">
                                            {/* <OrganiserForm /> */}
                                            <div dangerouslySetInnerHTML={{ __html: content }} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>
            }

            {/* <ContactMapArea /> */}
            {/* <PartnerAreaMain spacingClass="pt-130" /> */}
        </>
    )
};

export default TermsAndCondition;