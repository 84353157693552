import React, { useEffect, useState } from 'react';
import EventSlider from './EventSlider';
import { imageLoader } from '../../hooks/ImageLoader';
import GenresBgImg from '../../assets/img/genres/genres-bg-2.jpg';
import EventFacilityAndRestriction from './EventFacilityAndRestriction';
import axios from 'axios';
import { FcLike } from "react-icons/fc";
import FeedBack from '../Home/FeedBack';
import { Link, useLocation, useNavigation } from 'react-router-dom';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FaLocationDot } from "react-icons/fa6";
import useScrollToTop from '../../hooks/UseScrollToTop';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { FaCarAlt } from "react-icons/fa";
import Loader from '../preloander/Loander';
import { useTranslation } from 'react-i18next';
const EventDetailsMainArea = () => {

    const navigation = useNavigate()

    const { t } = useTranslation();

    const { createEvent } = t("screen");



    const [event, setEvent] = useState()
    const [eventImage, setEventImage] = useState()
    const [eventSchdule, setEventSchdule] = useState()
    const navigate = useNavigate()
    const location = useLocation()
    const search = location?.state?.id
    useScrollToTop();
    useEffect(() => {
        async function getEvent() {
            if (search) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/public/viewEvent/${search}`);
                    setEvent(response?.data || {});
                    if (response?.data?.event?.eventImage) {
                        setEventImage(JSON.parse(response.data.event.eventImage));
                    }
                    if (response?.data?.event?.shedule) {
                        setEventSchdule(JSON.parse(response.data.event.shedule));
                    }
                } catch (error) {
                    console.error("Error fetching event:", error);
                    // Handle error (e.g., show error message or redirect)
                }
            }
        }
        getEvent();
    }, [search]); // Include search in the dependency array



    const [height, setHeight] = useState()
    const [fontSize, setFontSize] = useState("10px")
    useEffect(() => {
        const currentWidth = window.innerWidth
        if (currentWidth >= 1200) {
            setHeight('30em')
            setFontSize('40px')
        } else if (currentWidth >= 750) {
            setHeight('30em')
            setFontSize('30px')

        } else {
            setHeight('25em')
            setFontSize('20px')

        }

    }, [])


    const [loading, setLoading] = useState(true)

    useEffect(() => {

        setTimeout(() => {

            setLoading(false)

        }, 1000);

    }, [])



    // Render loading state while fetching data
    // if (!event || Object.keys(event).length === 0) {
    //     return <div className='text-center flex justify-center ' >Loading...</div>;
    // }


    return (
        <>

            {
                loading ?

                    <Loader />

                    :

                    <section className="ms-genres-area pb-20 pt-20">
                        {/* <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-xl-7">
                            <div className="ms-genres-top text-center mb-50">

                                <div className="ms-fun-brand-bottom border-0">
                                    <div className="ms-fun-brand-location">
                                        <Link href="#" target="_blank">
                                            <FaLocationDot /> {event?.event?.city}</Link>
                                    </div>
                                    <div className="ms-fun-brand-rating">

                                        <div style={{ display: "flex" }}>
                                            <span>
                                                <FcLike size={30} />

                                            </span>
                                            <span style={{ marginTop: "0.2em", marginLeft: "0.3em" }}>{event?.event?.confirmedCount}</span>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                        <div className="ms-genres-shape include__bg" data-background="assets/img/genres/genres-shape.png">
                            <div className="container">
                                <div className="ms-genres-img p-relative ms-br-15 m-img fix mb-65">

                                    {/* <img src={eventImage ? eventImage?.[0] : GenresBgImg}
                                loading='lazy' style={{ width: '100%', height: "auto" }}
                                alt="genres image" width={"100"} height={"100"} />
                            <span className="ms-genres-price">Trending</span>
                            <div className="position-absolute w-100 " style={{ bottom: '3%', marginLeft: "2%" }}>
                                <h2 className="section__title" style={{ marginBottom: "0.25rem", }}>{event?.event?.eventName}</h2>
                                <button className='btn btn-info mx-2 text-white' >Book a Ride</button>
                                <button className='btn btn-warning mx-2 text-white'>Offer a Ride</button>
                            </div> */}
                                    <div className="ms-br-30 mx-auto include__bg z-index-1 ms-overlay-1">


                                        <Swiper
                                            modules={[Autoplay, Navigation]}
                                            spaceBetween={30}
                                            speed={4000}
                                            centeredSlides={true}
                                            // autoplay={{ delay: 6000 }}
                                            autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}

                                            navigation={{
                                                nextEl: '.swiper-button-next',
                                                prevEl: '.swiper-button-prev',

                                            }}
                                            className="ms-event-dots"
                                        >
                                            {eventImage?.map((item, index) => {
                                                // const imgArray = JSON.parse(item?.eventImage);
                                                // const firstImgCheck = imgArray?.[0];
                                                // let firstImg = firstImgCheck ? firstImgCheck : "assets/img/function-brand/function-brand-01.png";
                                                return (
                                                    <SwiperSlide key={index}>
                                                        <div className="position-relative overlay-container" style={{ marginTop: "0rem" }}>
                                                            <img src={item} alt={`Slide ${index + 1}`} className="img-fluid  " style={{ width: '100%' }} />
                                                            <div className="position-absolute w-100 ms-genres-img" style={{ bottom: '15%', marginLeft: "3%" }}>
                                                                <h3 className="section__title homeEventTitle"
                                                                    style={{ fontSize: fontSize, marginBottom: "0.25em", fontFamily: 'Prompt-Regular, sans-serif' }}
                                                                >
                                                                    <Link href="/event-details">{event?.event?.eventName}</Link>
                                                                </h3>
                                                                {/* <button className='btn btn-info mx-2 text-white'>Book a Ride</button>
                                                        <button className='btn btn-warning mx-2 text-white'>Offer a Ride</button> */}
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>

                                                )
                                            }

                                            )}
                                        </Swiper>
                                        <div className="swiper-button-next" style={{ color: "white" }}></div>
                                        <div className="swiper-button-prev" style={{ color: "white" }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-xxl-8 col-xl-7">
                                    <div className="ms-genres-left-wrap mb-30">
                                        <div className="ms-genres-tab2 mb-10 ml-5">
                                            <nav>
                                                <div className="nav nav-tabs ms-tab-button border-0 ms-border2-btn ms-tab-prevent"
                                                    id="nav-tab" role="tablist">
                                                    <button className="nav-link prevent active" id="nav-home-tab"
                                                        data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab"
                                                        aria-controls="nav-home" aria-selected="true">{createEvent.aboutEvent}</button>

                                                </div>
                                                <div className="ms-fun-brand-bottom border-0">
                                                    <div className="ms-fun-brand-location">
                                                        <Link href="#" target="_blank">
                                                            {/* <FaLocationDot /> {event?.event?.city} */}
                                                            <div style={{ display: 'flex', alignItems: 'center' , fontFamily: 'Prompt-Regular, sans-serif'}}>
                                                                <FaLocationDot />
                                                                <span style={{ marginLeft: '8px', fontSize: '1.5rem' }}>{event?.event?.city}</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="ms-fun-brand-rating">

                                                        <div style={{ display: "flex" }}>
                                                            <span>
                                                                <FcLike size={30} />

                                                            </span>
                                                            <span style={{ marginTop: "0.2em", marginLeft: "0.3em", fontFamily: 'Prompt-Regular, sans-serif' }}>{event?.event?.confirmedCount}</span>

                                                        </div>

                                                    </div>
                                                    <div className="ms-fun-brand-rating">

                                                        <div style={{ display: "flex" }}>
                                                            <span>
                                                                <FaCarAlt size={30} color='orange' />

                                                            </span>
                                                            <span style={{ marginTop: "0.2em", marginLeft: "0.3em", fontFamily: 'Prompt-Regular, sans-serif' }}>{event?.event?.ridesAvailable?.length} {createEvent.ridesavailable}</span>

                                                        </div>

                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                        <div className="ms-genres-tab2-content ml-5" >
                                            <div className="tab-content" id="nav-tabContent2">
                                                <div className="tab-pane fade show active" id="nav-home" role="tabpanel"
                                                    aria-labelledby="nav-home-tab" tabIndex={0}>
                                                    <span dangerouslySetInnerHTML={{ __html: event?.event?.eventDescription || "" }}></span>
                                                </div>
                                                <div className="work__features-btn mt-3 gap-8">
                                                    <button className="unfill__btn feature-unfill_btn mr-5 mb-3" onClick={() => navigation("/offer-ride")}  >Offer A Ride</button>
                                                    <button className="unfill__btn feature-unfill_btn" onClick={() => navigation("/book-ride")}  >Book A Ride </button>
                                                </div>
                                                {/* <div className="tab-pane fade" id="nav-profile" role="tabpanel"
                                            aria-labelledby="nav-profile-tab" tabIndex={0}>  
                                        </div>
                                        <div className="tab-pane fade" id="nav-contact" role="tabpanel"
                                            aria-labelledby="nav-contact-tab" tabIndex={0}>
                                        </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <EventSlider search={search} /> */}
                            </div>
                        </div>
                    </section>


            }





            {/* <FeedBack /> */}

        </>
    );
};

export default EventDetailsMainArea;