// import menu_data from '../../data/menu-data';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';



const MobileMenu = ({ isOpenInfoSidebar, setIsOpenInfoSidebar }) => {

    const { t, i18n } = useTranslation();
    const { home } = t("screen");

    const location = useLocation()
    const pathName = location.pathname;


    const menu_data = [
        {
            id: 1,
            title: home?.home,
            link: "/",
        },

        

        // {
        //     id: 3,
        //     title: home?.createEvent1,
        //     link: "/create-event",
        // },
        {
            id: 4,
            title: home?.bookARide1,
            link: "/book-ride",
        },

        {
            id: 5,
            title: home?.offerARide,
            link: "/offer-ride",
        },
        // {
        //     id: 7,
        //     title: "Features",
        //     data: "#features",
        // },
        // {
        //     id: 6,
        //     title: "Contact Us",
        //     link: "/contact-us",
        // },
        {
            id: 7,
            title: home?.becomeOrganiser,
            link: "/become-organisers",
        },
        {
            id: 2,
            title: home?.aboutUs,
            link: "/about-us"
        },
    ];



    const [languageChosen, setLanguageChosen] = useState(null);

    useEffect(() => {

        const language =  localStorage.getItem("selectedLanguage");

        if(language) {

            setLanguageChosen(language)

        }else{
            setLanguageChosen("En")

        }


    },[])



    const [openSubMenu, setOpenSubMenu] = useState(false);
    const [subMenuNum, setSubMenuNum] = useState(0);
    const [openMegaMenu, setOpenMegaMenu] = useState(false);
    const [megaMenuNum, setMegaMenuNum] = useState(0);
    const [openSubMegaMenu, setOpenSubMegaMenu] = useState(false);
    const [megaSubMenuNum, setMegaSubMenuNum] = useState(0);

    const handleActiveSubMenu = (index) => {
        setOpenSubMenu(!openSubMenu);
        setSubMenuNum(index);
    };

    const handleActiveMegaMenu = (index) => {
        setOpenMegaMenu(!openMegaMenu);
        setMegaMenuNum(index);
    };

    const handleActiveSubMegaMenu = (index) => {
        setOpenSubMegaMenu(!openSubMegaMenu);
        setMegaSubMenuNum(index);
    };

    const toggleLanguage = () => {
        setLanguageChosen((prevLanguage) => (prevLanguage === "En" ? "It" : "En"));
        i18n.changeLanguage(languageChosen == "It" ? "En" : "It")
        localStorage.setItem("selectedLanguage", languageChosen == "It" ? "En" : "It")

      };

    return (
        <>
            <ul>
                {menu_data?.map((item) => (
                    <li key={item.id} className={`${item.hasDropdown === true ? "has-dropdown" : ""} ${item.megaMenu === true ? "has-mega-menu" : ""} ${openMegaMenu && megaMenuNum === item.id ? "dropdown-opened" : ""}`}>
                        {/* <a href={item.link} className='link'>{item.title}</a> */}
                        <RouterLink onClick={() => setIsOpenInfoSidebar(!isOpenInfoSidebar)} className={`${pathName == item.link ? "link" : ""}  `} to={item.link}>
                            {item.title}
                        </RouterLink>
                        {item?.hasDropdown === true && (
                            <>
                                {item.submenus?.map((subItem, index) => (
                                    <ul key={index} className="submenu" style={{ display: openSubMenu && subMenuNum === item.id ? "block" : "none" }}>
                                        <li><a href={subItem.link} className='link'>{subItem.title}</a></li>
                                    </ul>
                                ))}
                            </>
                        )}
                        {item?.megaMenu === true && (
                            <>
                                <ul className="mega-menu" style={{ display: openMegaMenu && megaMenuNum === item.id ? "block" : "none" }}>
                                    {item?.mega_menus?.map((megaItem, mIndex) => (
                                        <li key={mIndex}>
                                            <a className="mega-menu-title" href="#">{megaItem.title}</a>
                                            <ul style={{ display: openSubMegaMenu && megaSubMenuNum === mIndex ? "block" : "none" }}>
                                                {megaItem?.submenus?.length && megaItem?.submenus?.map((subMegaItem, subMegaIndex) => (
                                                    <li key={subMegaIndex}><a href={subMegaItem.link}>{subMegaItem.title}</a></li>
                                                ))}
                                            </ul>
                                            <a onClick={() => handleActiveSubMegaMenu(mIndex)} className={`mean-expand ${openSubMegaMenu && megaSubMenuNum === mIndex ? "mean-clicked" : ""}`} href="#" style={{ fontSize: '18px' }}><i className="fal fa-plus"></i></a>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {item?.hasDropdown === true && <a onClick={() => handleActiveSubMenu(item.id)} className={`mean-expand ${openSubMenu && subMenuNum === item.id ? "mean-clicked" : ""}`} href="#" style={{ fontSize: '18px' }}><i className="fal fa-plus"></i></a>}
                        {item?.megaMenu === true && <a onClick={() => handleActiveMegaMenu(item.id)} className={`mean-expand ${openMegaMenu && megaMenuNum === item.id ? "mean-clicked" : ""}`} href="#" style={{ fontSize: '18px' }}><i className="fal fa-plus"></i></a>}
                    </li>
                ))}

                <li >
                    {/* <a href={item.link} className='link'>{item.title}</a> */}
                    

                    <div className='flex md:hidden' style={{ width:"60%" ,flexDirection:"row", alignItems:"center" }}>
                        <a style={{color:"white"}} href="#">Italian</a>
                        <input  onClick={toggleLanguage} class="switch" type="checkbox" checked={languageChosen == "En"  ? false : true }/>
                    </div>

                </li>

                <li >
                    {/* <a href={item.link} className='link'>{item.title}</a> */}

                    <div className='flex md:hidden' style={{ width:"60%" ,flexDirection:"row", alignItems:"center" }}>
                        <a style={{color:"white"}} href="#">English</a>
                        <input  onClick={toggleLanguage} class="switch" type="checkbox" checked={languageChosen == "En"  ? true : false }/>
                    </div>

                </li>
            </ul>
        </>
    );
};

export default MobileMenu;
