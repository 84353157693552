import React, { useEffect, useState } from 'react';
import eventPic from "../../assets/event.png";
import agendaPic from "../../assets/agenda.png";
import userPic from "../../assets/user.png";
import ridePic from "../../assets/rides.png";
import HeaderOne from '../../layout/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import bannerTitleBg2 from '../../assets/img/banner/title-bg.jpg';
import { IoTicketSharp } from "react-icons/io5";
import { AiTwotoneLike } from "react-icons/ai";
import { IoIosPersonAdd } from "react-icons/io";
import { FaUserGroup } from "react-icons/fa6";
import { FaComment } from "react-icons/fa";
import { CiBookmark } from "react-icons/ci";
import { FaInfoCircle } from "react-icons/fa";

import { useNavigate } from 'react-router-dom';
import ride1 from "../../assets/BookARide/ride1.png"
import ride2 from "../../assets/BookARide/ride2.png"
import ride3 from "../../assets/BookARide/ride3.png"
import ride4 from "../../assets/BookARide/ride4.png"
import ride5 from "../../assets/BookARide/ride5.png"
import ride6 from "../../assets/BookARide/ride6.png"
import ride7 from "../../assets/BookARide/ride7.png"
import ride8 from "../../assets/BookARide/ride8.png"
import event1 from "../../assets/BookARide/event1.png"
import event2 from "../../assets/BookARide/event2.png"
import event3 from "../../assets/BookARide/event3.png"
import useScrollToTop from '../../hooks/UseScrollToTop';
import Loader from '../preloander/Loander';
const BookAEvent = () => {
    useScrollToTop()

    const [activeTab, setActiveTab] = useState('events');
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        setTimeout(() => {

            setLoading(false)

        }, 1000);

    }, [])

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const navigate = useNavigate()

    return (

        <>

            {
                loading ?

                    <Loader />

                    :

                    <div >
                        {/* <HeaderOne /> */}
                        <Breadcrumb title="Book A Event" />
                        <section className="header" >
                            <div className="container-fluid d-flex" style={{ padding: "0%" }}>
                                <div className="">
                                    <div className="container-fluid d-flex " style={{ justifyContent: "center", alignItems: "center", marginBottom: 0, padding: "0" }}>
                                        <div className="row justify-center items-center w-full"  >
                                            <div className="col-md-3 d-flex flex-column  bg-[rgb(232,67,81)] justify-center items-center" style={{ height: "40em" }}>
                                                <div >
                                                    <img src={eventPic} alt="" style={{ height: "25em" }}

                                                    />
                                                </div>
                                                {/* section__subtitle */}
                                                <p className="text-white text-center mt-2" style={{ fontSize: "26px", fontWeight: "bold" , fontFamily: 'Prompt-Regular, sans-serif'}} onClick={() => handleTabClick('events')} >Event</p>
                                            </div>
                                            <div className="col-md-3 d-flex flex-column align-items-center  bg-[#ed7551] justify-center items-center" style={{ height: "40em" }}>
                                                <div >
                                                    <img src={ridePic} alt="" style={{ height: "25em" }}

                                                    />
                                                </div>
                                                <p className="text-white text-center mt-2" style={{ fontSize: "26px", fontWeight: "bold", fontFamily: 'Prompt-Regular, sans-serif' }} onClick={() => handleTabClick('rides')} >Rides</p>
                                            </div>
                                            <div className="col-md-3 d-flex flex-column align-items-center  bg-[#f29552] justify-center items-center" style={{ height: "40em" }}>
                                                <div >
                                                    <img src={agendaPic} alt="" style={{ height: "25em" }} />
                                                </div>
                                                <p className="text-white text-center mt-2" style={{ fontSize: "26px", fontWeight: "bold", fontFamily: 'Prompt-Regular, sans-serif' }} onClick={() => handleTabClick('agenda')} >Agenda</p>
                                            </div>
                                            <div className="col-md-3 d-flex flex-column align-items-center  bg-[#f7b57c] justify-center items-center" style={{ height: "40em" }}>
                                                <div >
                                                    <img src={userPic} alt="" style={{ height: "25em" }} />
                                                </div>
                                                <p className="text-white text-center mt-2" style={{ fontSize: "26px", fontWeight: "bold", fontFamily: 'Prompt-Regular, sans-serif' }} onClick={() => handleTabClick('users')} >User</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        {/* event */}
                                        <div className=' bg-[#e84351] h-30' >
                                            <div >
                                                <div className='justify-center text-center pt-6'>
                                                    <h2 className='text-3xl text-white p-2'>How To Book A Event</h2>

                                                </div>
                                                <div className='flex justify-center items-center py-10 px-6'>
                                                    <div className='ms-fun-brand-wrap3   '>
                                                        <div className='flex justify-center items-center flex-col ' >
                                                            <img src={event1} alt="" style={{ height: "30em", }}
                                                            />
                                                            <p className='text-white text xl text-start'>1: First Go To The Event Section and then Choose A Event</p>
                                                        </div>
                                                        <div className='flex justify-center items-center flex-col '>
                                                            <img src={event2} alt="" style={{ height: "30em" }} />
                                                            <p className='text-white text xl '>2: Scroll Down and click the book event icon (last Option).  </p>
                                                        </div>
                                                        <div className='flex justify-center items-center flex-col '>
                                                            <img src={event3} alt="" style={{ height: "30em" }} />
                                                            <p className='text-white text xl '>3: After Opening  pop up Box you can click  buy the tickets button</p>
                                                        </div>


                                                    </div>
                                                </div>




                                            </div>
                                        </div>
                                        {/* RIDES */}
                                        {/* <div className='bg-[#ed7551] h-30'>
                                <div>
                                    <div className='justify-center text-center pt-6'>
                                        <h2 className='text-3xl text-white p-2'>How To Book A Ride</h2>
                                    </div>
                                    <div className='flex justify-center items-center py-10'>
                                        <div className='grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-3'>
                                            <div className='flex flex-col justify-center items-center'>
                                                <img src={ride1} alt="" style={{ height: "30em" }} />
                                                <p className='text-white text-mg text-center'>
                                                    1: First Go To The Ride Section and then Choose the FIND A RIDE Option
                                                </p>
                                            </div>
                                            <div className='flex flex-col justify-center items-center'>
                                                <img src={ride2} alt="" style={{ height: "30em" }} />
                                                <p className='text-white text-mg text-center'>
                                                    2: Fill The Data like Your Pickup Location Event Name Date And time and then search ride.
                                                </p>
                                            </div>
                                            <div className='flex flex-col justify-center items-center'>
                                                <img src={ride3} alt="" style={{ height: "30em" }} />
                                                <p className='text-white text-mg text-center'>
                                                    3: Choose the Ride offered by drivers
                                                </p>
                                            </div>
                                            <div className='flex flex-col justify-center items-center'>
                                                <img src={ride4} alt="" style={{ height: "30em" }} />
                                                <p className='text-white text-mg text-center'>
                                                    4: View All The Route Details And move to the next section
                                                </p>
                                            </div>
                                            <div className='flex flex-col justify-center items-center'>
                                                <img src={ride5} alt="" style={{ height: "30em" }} />
                                                <p className='text-white text-mg text-center'>
                                                    5: View The Drive and Car Details and fill the number of seats and Book the ride
                                                </p>
                                            </div>
                                            <div className='flex flex-col justify-center items-center'>
                                                <img src={ride6} alt="" style={{ height: "30em" }} />
                                                <p className='text-white text-mg text-center'>
                                                    6: View Your Summary And pay The Amount and book the ride
                                                </p>
                                            </div>
                                            <div className='flex flex-col justify-center items-center'>
                                                <img src={ride8} alt="" style={{ height: "30em" }} />
                                                <p className='text-white text-mg text-center'>
                                                    7: After Clicking the PAY & Book you are redirected to the PayPal website and click to pay the amount
                                                </p>
                                            </div>
                                            <div className='flex flex-col justify-center items-center'>
                                                <img src={ride7} alt="" style={{ height: "30em" }} />
                                                <p className='text-white text-mg text-center'>
                                                    7: After successful payment, you can view your booked ride inside the agenda section in rides taken option
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                                        {/* AGENDA */}
                                        {/* <div className='grid md:grid-cols-2 sm:grid-cols-1 bg-[#f29552] justify-center items-center p-10 h-30' >
                                <div className='flex justify-center'>
                                    <img src={agendaPic} alt="" style={{ height: "30em" }} />
                                </div>
                                <div >
                                    <h2 className='text-2xl text-white pb-2'>AGENDA</h2>


                                    <div >
                                        <div className='grid lg:grid-cols-2 sm:grid-cols-1'>
                                            <div className='flex gap-3 mb-3'>

                                                <div>
                                                    <h1 className='text-lg text-white pb-1'>SHARE YOUR AGENDA</h1>

                                                </div>

                                            </div>

                                        </div>
                                        <div className="work__features-btn mt-3">
                                            <button className="unfill__btn feature-unfill_btn" href="https://play.google.com/">Get Started</button>
                                        </div>
                                    </div>

                                </div>
                            </div> */}
                                        {/* USERS */}
                                        {/* <div className='grid md:grid-cols-2 sm:grid-cols-1 bg-[#f7b57c] justify-center items-center p-10 h-30' >

                                <div >
                                    <h2 className='text-2xl text-white pb-2 '>USERS</h2>


                                    <div >
                                        <div className='grid lg:grid-cols-2 sm:grid-cols-1'>
                                            <div className='flex gap-3 mb-3'>

                                                <div>

                                                    <p className='text-sm text-white pb-2'>INCREASE YOUR
                                                        NETWORK</p>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="work__features-btn mt-3">
                                            <button className="unfill__btn feature-unfill_btn" href="https://play.google.com/">Get Started</button>
                                        </div>
                                    </div>

                                </div>
                                <div className='flex justify-center'>
                                    <img src={eventPic} alt="" style={{ height: "30em" }} />
                                </div>
                            </div> */}
                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>


            }
        </>

    );
};

export default BookAEvent;

