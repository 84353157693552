import React, { useEffect, useState } from 'react';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';

import { useNavigate } from 'react-router-dom';
import useScrollToTop from '../../hooks/UseScrollToTop';
import { FaArrowRight } from "react-icons/fa6";

// import searchRide from "../../assets/img/images/Choose the event to reach(3).png";
import searchRide from "../../assets/img/images/Choose the event to reach(4).png";
// import routeDetail from "../../assets/img/images/Select your pick up location(4).png";
import routeDetail from "../../assets/img/images/Select your pick up location(5).png";
// import bookRide from "../../assets/img/images/Book the best offer(2).png";
import bookRide from "../../assets/img/images/Book the best offer(3).png";
import payment from "../../assets/img/images/Meet the driver and Enjoy the event(3).png"
import { useTranslation } from 'react-i18next';
import Loader from '../preloander/Loander';

const BookARide = () => {

    const { t } = useTranslation();

    const { bookARide } = t("screen");

    const [loading, setLoading] = useState(true)

    useEffect(() => {

        setTimeout(() => {

            setLoading(false)

        }, 1000);

    }, [])



    useScrollToTop();

    const [activeTab, setActiveTab] = useState('events');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const navigate = useNavigate()
    return (

        <>

            {
                loading ?

                    <Loader />

                    :

                    <div >
                        {/* <HeaderOne /> */}
                        <Breadcrumb title={bookARide?.HowToBookARide} />

                        {/* <section className="header" >
                 <div className="container-fluid d-flex" style={{ padding: "0%" }}>
                     <div className="">
 
 
                         <div>
                             
                             <div
                                 className=' h-30'
                             >
                                 <div>
                                     <div className='justify-center text-center pt-6'>
                                         <h2 className='text-3xl text-white p-2'>How To Book A Ride</h2>
                                     </div>
                                     <div className='flex justify-center items-center py-10'>
                                         <div className='grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-3'>
                                             <div className='flex flex-col justify-center items-center'>
                                                 <img src={ride1} alt="" style={{ height: "30em" }} />
                                                 <p className='text-white text-mg text-center'>
                                                     1: First Go To The Ride Section and then Choose the FIND A RIDE Option
                                                 </p>
                                             </div>
                                             <div className='flex flex-col justify-center items-center'>
                                                 <img src={ride2} alt="" style={{ height: "30em" }} />
                                                 <p className='text-white text-mg text-center'>
                                                     2: Fill The Data like Your Pickup Location Event Name Date And time and then search ride.
                                                 </p>
                                             </div>
                                             <div className='flex flex-col justify-center items-center'>
                                                 <img src={ride3} alt="" style={{ height: "30em" }} />
                                                 <p className='text-white text-mg text-center'>
                                                     3: Choose the Ride offered by drivers
                                                 </p>
                                             </div>
                                             <div className='flex flex-col justify-center items-center'>
                                                 <img src={ride4} alt="" style={{ height: "30em" }} />
                                                 <p className='text-white text-mg text-center'>
                                                     4: View All The Route Details And move to the next section
                                                 </p>
                                             </div>
                                             <div className='flex flex-col justify-center items-center'>
                                                 <img src={ride5} alt="" style={{ height: "30em" }} />
                                                 <p className='text-white text-mg text-center'>
                                                     5: View The Drive and Car Details and fill the number of seats and Book the ride
                                                 </p>
                                             </div>
                                             <div className='flex flex-col justify-center items-center'>
                                                 <img src={ride6} alt="" style={{ height: "30em" }} />
                                                 <p className='text-white text-mg text-center'>
                                                     6: View Your Summary And pay The Amount and book the ride
                                                 </p>
                                             </div>
                                             <div className='flex flex-col justify-center items-center'>
                                                 <img src={ride8} alt="" style={{ height: "30em" }} />
                                                 <p className='text-white text-mg text-center'>
                                                     7: After Clicking the PAY & Book you are redirected to the PayPal website and click to pay the amount
                                                 </p>
                                             </div>
                                             <div className='flex flex-col justify-center items-center'>
                                                 <img src={ride7} alt="" style={{ height: "30em" }} />
                                                 <p className='text-white text-mg text-center'>
                                                     7: After successful payment, you can view your booked ride inside the agenda section in rides taken option
                                                 </p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             </div>
 
                             
                         </div>
 
                     </div>
                 </div>
             </section> */}

                        <section className="header" >
                            <div className="container-fluid d-flex" style={{ padding: "0%" }}>
                                <div style={{ width: "100%" }}>
                                    <div>
                                        <div
                                            className=' h-30'
                                        >
                                            <div>
                                                {/* <div className='justify-center text-center pt-6'>
                                                    <h1  style={{ fontFamily: 'Prompt-Regular, sans-serif',textTransform:"none" }} className='section__title text-3xl text-white p-2'>{bookARide?.HowToBookARide}</h1>
                                                </div> */}

                                                <div className="container ">

                                                    {/* 1st */}
                                                    <div className="grid md:grid-cols-2 grid-cols-1 justify-around items-center ">
                                                        {/* Image Container */}
                                                        <div className="py-5 md:w-auto w-full d-flex justify-center img-container">
                                                            <img src={searchRide} alt="Search Ride" className="rounded-xl" />
                                                        </div>

                                                        {/* Text Content */}
                                                        <div className="md:w-auto w-full flex justify-center">
                                                            <div className="p-2 text-white flex flex-col gap-1 justify-start items-start">
                                                                <div className="flex gap-1 items-start">
                                                                    <h2 className="section__subtitle text-3xl mb-1 font-sans text-white " style={{ fontFamily: 'Oregano-Italic, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.ChooseTheEventToReach}
                                                                    </h2>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">1.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif',textTransform:"none" }}>
                                                                        {bookARide?.Navigatethemapoftrendingeventsaroundyou}
                                                                    </p>
                                                                </div>

                                                                {/* <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">2.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif',textTransform:"none" }}>
                                                                        {bookARide?.usethemaptovisualizethedistance}
                                                                    </p>
                                                                </div> */}
                                                                <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">2.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif',textTransform:"none" }}>
                                                                        {bookARide?.jointheeventwhereyourfriendsaregoingnext}
                                                                    </p>
                                                                </div>
                                                                <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">3.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif',textTransform:"none" }}>
                                                                        {bookARide?.seetheavailablerides}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    {/* 2st */}
                                                    <div className="grid md:grid-cols-2 grid-cols-1  items-center ">
                                                        <div className=" md:hidden py-5 w-full flex justify-center img-container">
                                                            <img src={routeDetail} alt="Route Detail" className="rounded-xl" />
                                                        </div>
                                                        {/* Text Content */}
                                                        <div className=" w-full flex justify-center">
                                                            <div className="p-2 text-white hidden md:flex flex-col gap-1 justify-start items-start">
                                                                <div className="flex gap-1 items-start">
                                                                    <h2 className="section__subtitle text-3xl mb-1 font-sans text-white" style={{ fontFamily: 'Oregano-Italic, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.Selectyourpickuplocation}
                                                                    </h2>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">1.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.ChooseTheRideOfferedBy}
                                                                    </p>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">2.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.ViewAllTheRouteDetails}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Image Container */}
                                                        <div className=" py-5  w-full hidden md:flex justify-center img-container">
                                                            <img src={routeDetail} alt="Route Detail" className="rounded-xl" />
                                                        </div>

                                                        <div className=" md:hidden w-full flex justify-center">
                                                            <div className="p-2 text-white flex flex-col gap-1 justify-start items-start">
                                                                <div className="flex gap-1 items-start">
                                                                    <h2 className="section__subtitle text-3xl mb-1 font-sans text-white" style={{ fontFamily: 'Oregano-Italic, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.Selectyourpickuplocation}
                                                                    </h2>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">1.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.ChooseTheRideOfferedBy}
                                                                    </p>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">2.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.ViewAllTheRouteDetails}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* 3rd */}
                                                    <div className="grid md:grid-cols-2 grid-cols-1 justify-around items-center ">
                                                        {/* Image Container */}
                                                        <div className="py-5 md:w-auto w-full d-flex justify-center img-container">
                                                            <img src={bookRide} alt="Search Ride" className="rounded-xl" />
                                                        </div>

                                                        {/* Text Content */}
                                                        <div className="md:w-auto w-full d-flex justify-center">
                                                            <div className="p-2 text-white flex flex-col gap-1 justify-start items-start">
                                                                <div className="flex gap-1 items-start">
                                                                    <h2 className="section__subtitle text-3xl mb-1 font-sans text-white" style={{ fontFamily: 'Oregano-Italic, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.Bookthebestoffer}
                                                                    </h2>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">1.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.selectaonewayorreturntrip}
                                                                    </p>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">2.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.selectthemostconvenientpriceandtime}
                                                                    </p>
                                                                </div>
                                                                <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">3.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.seethedriverratingandifheownstheeventticket}
                                                                    </p>
                                                                </div>
                                                                <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">4.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.edityourpickuplocation}
                                                                    </p>
                                                                </div>
                                                                <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">5.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.finalizethepaymenttobooktheseat}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* 4th */}
                                                    <div className="grid md:grid-cols-2 grid-cols-1 justify-around items-center ">

                                                        <div className=" md:hidden py-5 md:w-auto w-full flex justify-center img-container">
                                                            <img src={payment} alt="Route Detail" className="rounded-xl" />
                                                        </div>
                                                        {/* Text Content */}
                                                        <div className=" hidden md:w-auto w-full md:flex justify-center">
                                                            <div className="p-2 text-white flex flex-col gap-1 justify-start items-start">
                                                                <div className="flex gap-1 items-start">
                                                                    <h2 className="section__subtitle text-2xl lg:text-3xl mb-1 font-sans text-white" style={{ fontFamily: 'Oregano-Italic, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.Meetthedriverandenjoytheevent}
                                                                    </h2>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">1.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.openthereservationfromyouragenda}
                                                                    </p>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">2.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.Followthedrivergoingtotheagreedpickuppoint}
                                                                    </p>
                                                                </div>
                                                                {/* <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">3.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.followthedriverinhisroute}
                                                                    </p>
                                                                </div> */}
                                                                <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">3.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.contactthedriverforanynotification}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* Image Container */}
                                                        <div className="hidden py-5 md:w-auto w-full md:flex justify-center img-container">
                                                            <img src={payment} alt="Route Detail" className="rounded-xl" />
                                                        </div>

                                                        <div className=" md:hidden md:w-auto w-full flex justify-center">
                                                            <div className="p-2 text-white flex flex-col gap-1 justify-start items-start">
                                                                <div className="flex gap-1 items-start">
                                                                    <h2 className="section__subtitle text-2xl lg:text-3xl mb-1 font-sans text-white" style={{ fontFamily: 'Oregano-Italic, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.Meetthedriverandenjoytheevent}
                                                                    </h2>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">1.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.openthereservationfromyouragenda}
                                                                    </p>
                                                                </div>

                                                                <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">2.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.Followthedrivergoingtotheagreedpickuppoint}
                                                                    </p>
                                                                </div>
                                                                {/* <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">3.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.followthedriverinhisroute}
                                                                    </p>
                                                                </div> */}
                                                                <div className="flex gap-1 items-start">
                                                                    <span className=" mr-2 font-bold text-lg">3.</span>
                                                                    <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                                        {bookARide?.contactthedriverforanynotification}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>


            }
        </>

    );
};

export default BookARide;

