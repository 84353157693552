import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import FooterLogo from "../../assets/img/logo/logo.svg";
import FooterBg from "../../assets/img/bg/sound-bg.png";
import FooterSubscribe from "./FooterSubscribe";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa6";
import axios from 'axios';
import appleStore from "../../assets/img/cta/app-store.png"
import playStore from "../../assets/img/cta/play-store.png"
import headerLogo from "../../assets/img/logo/logo.svg";
import { useTranslation } from "react-i18next";

const FooterOne = () => {
    const [categoryList, setCategoryList] = useState({});
    const [subCategoryList, setSubCategoryList] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const categoryResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/public/listAllCategory`);
                setCategoryList(categoryResponse?.data);
                // const subCategoryResponse = await axios.get(`${process.env.NEXT_PUBLIC_BASE_URL}/api/public/listAllSubCategory`);
                // setSubCategoryList(subCategoryResponse?.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);


    // translation handling
    const { t } = useTranslation();
    const { home } = t("screen");



    return (
        <>
            <footer>
                <div
                    className="ms-footer-bg ms-footer-overlay zindex-1 include__bg "
                    style={{ backgroundImage: `url(${FooterBg.src})` }}
                >
                    <div className="ms-footer-top ">
                        <div className="container">
                            <div className="ms-footer-border pb-10">
                                <div className="row">
                                    <div
                                        className="py-5"
                                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                    >
                                        <Link href="#">
                                            {/* width={143} height={45} ,priority*/}
                                            <img style={{ width: "15em", }} src={headerLogo} alt="logo not found" />
                                        </Link>
                                        {/* <div className="flex justify-center">
                                            <div className="ms-footer-widget mb-50">
                                                <h3 className="ms-footer-title">Trending Genres</h3>
                                                <ul>
                                                    <li>
                                                        <Link href="/genres">Rock</Link>
                                                    </li>
                                                    <li>
                                                        <Link href="/genres">Hip-Hop</Link>
                                                    </li>
                                                    <li>
                                                        <Link href="/genres">Metal</Link>
                                                    </li>
                                                    <li>
                                                        <Link href="/genres">POP</Link>
                                                    </li>
                                                    <li>
                                                        <Link href="/genres">Country</Link>
                                                    </li>
                                                    <li>
                                                        <Link href="/genres">Jazz & Swing</Link>
                                                    </li>
                                                    <li>
                                                        <Link href="/genres">Opera</Link>
                                                    </li>
                                                    <li>
                                                        <Link href="/genres">Indies</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}

                                        {/* <div className="flex justify-center">
                                            <div className="ms-footer-widget mb-50">
                                                <h3 className="ms-footer-title">Categories</h3>
                                                <ul>
                                                    {
                                                        categoryList?.category?.map((item) => (
                                                            <li key={item._id}>
                                                                <Link href="#">{item?.category}</Link>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div> */}
                                        {/* <div className="flex justify-center">
                                            <div className="ms-footer-widget mb-50">
                                                <h3 className="ms-footer-title">Company</h3>
                                                <ul>
                                                    <li>
                                                        <Link href="/about">Who We Are</Link>
                                                    </li>
                                                    <li>
                                                        <Link href="/contact">News & Press Release</Link>
                                                    </li>
                                                    <li>
                                                        <Link href="/contact">Advertise With Us</Link>
                                                    </li>
                                                    <li>
                                                        <Link href="/faq">Help & F.A.Q</Link>
                                                    </li>
                                                    <li>
                                                        <Link href="/faq">Contact Us</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}

                                    </div>

                                    <div className="container">
                                        <div className="row">
                                            {/* <div className="col-xxl-6 col-lg-6 col-md-12">
                                                <div>
                                                    <h3 className="ms-footer-title">Subscribe To <span>Our Newsletter</span></h3>
                                                    <FooterSubscribe />
                                                    <div className="ms-footer-warning mb-25">
                                                        <p>You Don’t Get Any Spam Message !</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="col-xxl-12 col-lg-12 col-md-12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div >
                                                    <h3 style={{fontFamily: 'Prompt-Regular, sans-serif'}} className="ms-footer-title text-center">{home?.downloadOurApp}</h3>
                                                    <div className="d-flex gap-2">
                                                        <Link href="https://www.apple.com/">
                                                            <img src={appleStore} alt="Apple Store" />
                                                        </Link>
                                                        <Link href="https://play.google.com/">
                                                            <img src={playStore} alt="Google Play Store" />
                                                        </Link>
                                                    </div>
                                                    <div className="ms-footer-social mt-5">
                                                        <p style={{fontFamily: 'Prompt-Regular, sans-serif'}} className="text-center">{home?.followUsOn}</p>
                                                        <Link to="#" title="LinkedIn" >
                                                            <FaLinkedin />
                                                        </Link>
                                                        <Link to="https://x.com/stagedriving" title="Twitter" target="_blank">
                                                            <FaTwitter />
                                                        </Link>
                                                        <Link to="https://www.facebook.com/stagedriving" title="Facebook" target="_blank">
                                                            <FaFacebook />
                                                        </Link>
                                                        <Link to="https://www.instagram.com/stagedriving" title="Instagram" target="_blank">
                                                            <FaInstagram />
                                                        </Link>
                                                        <Link to="#" title="TikTok" >
                                                            <FaTiktok />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-xxl-6 col-lg-6 col-md-12 d-flex justify-content-md-center justify-content-start align-items-center "
                                            //  style={{display :"flex", justifyContent:"center", alignItems:"center"}}
                                            
                                             >
                                                <div className="ms-footer-widget mb-50 mt-20">
                                                    <h3 className="ms-footer-title">Policies</h3>
                                                    <ul>
                                                        <li>
                                                            <Link to="/terms-condition">Terms & Conditions</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/refund-policy">Refund Policy</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/privacy-policy">Privacy Policy</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ms-footer-bottom py-3">
                        <div className="container">

                            <div className="row">

                                {/* <div className="ms-footer-bottom-wrap align-items-center d-flex flex-wrap justify-content-between py-10"> */}


                                <div
                                    className="col-xxl-6 col-lg-6 col-md-12"
                                // className="ms-footer-copy"
                                >
                                    <p>©  Stage Driving 2024, {home?.allRightsReserved}</p>
                                </div>

                                <div
                                    className="col-xxl-6 col-lg-6 col-md-12 d-flex justify-content-md-end justify-content-start align-items-center "

                                // className="ms-footer-logo "
                                >
                                    <ul style={{ display: "flex", gap: "10px", fontFamily: 'Prompt-Regular, sans-serif' }}>
                                        <li>
                                            <Link to="/terms-condition">Terms & Conditions</Link>
                                        </li>
                                        <li>
                                            <Link to="/refund-policy">Refund Policy</Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-policy">Privacy Policy</Link>
                                        </li>
                                    </ul>
                                    {/* <Link href="#">
                                        <img
                                            style={{ width: "100%", height: "auto" }}
                                            src={FooterLogo}
                                            alt="logo"
                                        />
                                    </Link> */}
                                </div>

                            </div>


                            {/* <div className="ms-footer-copy">
                                    <p>Powered By <span style={{ color: "rgb(255, 113, 0)" }}>SPK Technosoft</span> ©</p>
                                </div> */}
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default FooterOne;
