import i18n from "i18next";
import LanuageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";


import english from "./translations/en.json";
import italy from "./translations/it.json";


i18n.use(LanuageDetector).use(initReactI18next).init({
    debug: true,
    lng: localStorage.getItem("selectedLanguage") ? localStorage.getItem("selectedLanguage") : "En",
    returnObjects: true,
    resources: {
        En: { translation: english },
        It: { translation: italy },
    }
})