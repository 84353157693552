import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import Loader from './Loander'

const PreLoander = () => {
  return (
    <Suspense fallback={<Loader />}>
    {<Outlet />}
  </Suspense>
  )
}

export default PreLoander