import React, { useState } from 'react';
import eventPic from "../../assets/event.png";
import agendaPic from "../../assets/agenda.png";
import userPic from "../../assets/user.png";
import ridePic from "../../assets/rides.png";
import HeaderOne from '../../layout/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import bannerTitleBg2 from '../../assets/img/banner/title-bg.jpg';
import { IoTicketSharp } from "react-icons/io5";
import { AiTwotoneLike } from "react-icons/ai";
import { IoIosPersonAdd } from "react-icons/io";
import { FaUserGroup } from "react-icons/fa6";
import { FaComment } from "react-icons/fa";
import { CiBookmark } from "react-icons/ci";
import { FaInfoCircle } from "react-icons/fa";

import { useNavigate } from 'react-router-dom';
import ride1 from "../../assets/BookARide/ride1.png"
import ride2 from "../../assets/BookARide/ride2.png"
import ride3 from "../../assets/BookARide/ride3.png"
import ride4 from "../../assets/BookARide/ride4.png"
import ride5 from "../../assets/BookARide/ride5.png"
import ride6 from "../../assets/BookARide/ride6.png"
import ride7 from "../../assets/BookARide/ride7.png"
import ride8 from "../../assets/BookARide/ride8.png"
import event1 from "../../assets/BookARide/event1.png"
import event2 from "../../assets/BookARide/event2.png"
import event3 from "../../assets/BookARide/event3.png"
import useScrollToTop from '../../hooks/UseScrollToTop';
import { MdOutlineAccountBox } from "react-icons/md";
import { LuDot } from "react-icons/lu";
// import { FaArrowRight } from "react-icons/fa";
import { RiLoginBoxFill } from "react-icons/ri";
import { FaWpforms } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa6";
import eventCar from "../../assets/img/logo/eventCar.png"
import WhyChooseUs from '../Home/WhyChooseUs';






const NotFound = () => {
    useScrollToTop();

    const [activeTab, setActiveTab] = useState('events');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const navigate = useNavigate()
    return (
        <div >
            {/* <HeaderOne /> */}
            <Breadcrumb title="PAGE NOT FOUND" />
            {/* <section className="header" >
                <div className="container-fluid d-flex" style={{ padding: "0%" }}>
                    <div style={{ width: "100%" }}>


                        <div>

                            <div className=' h-30'>
                                <div>

                                    <div className="container">
                                        <div className="row align-items-center ">
                                            <div className=" py-5 col-xl-6 col-md-12 d-flex  justify-content-center img-container">

                                                <img src={eventCar} alt="" />

                                            </div>
                                            <div className="col-xl-6 col-md-12 d-flex justify-content-center" >
                                                <div className='p-2' style={{ color: "white", gap: "4px", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "self-start" }}>

                                                    <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                        <h1 style={{ fontSize: "2.2em", marginBottom:"10px" }} className='text-white'>About Stage Driving.</h1>
                                                    </div>

                                                    <div style={{ display: "flex", marginBottom: "5px", gap: "3px", flexDirection: "column", alignItems: "flex-start" }}>
                                                        <h3 style={{ fontSize: "1.50em" }} className='text-white'>To purchase an event ticket is easy...</h3>
                                                        <h3 style={{ fontSize: "1.50em" }} className='text-white'>but why nobody is enough worried about to comfortably reach the event sold?</h3>
                                                    </div>

                                                    <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                        <h3 style={{ fontSize: "1.50em" }} className='text-white'>The ride sharing App connecting you with events and other funs, saving money and sharing the experience, in a really unique way.</h3>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>





                        </div>

                    </div>
                </div>
            </section> */}

            {/* <WhyChooseUs /> */}

        </div>
    );
};

export default NotFound;

