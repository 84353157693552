import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FcLike } from "react-icons/fc";
// import popular_band_fuction_data from '../../data/popular-band-function-data';
import GetRatting from '../../hooks/GetRating';
import { imageLoader } from '../../hooks/ImageLoader';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FaStar } from "react-icons/fa6";
import { CiStar } from "react-icons/ci";
import { useTranslation } from 'react-i18next';
const TopDrivers = () => {
    const [data, setData] = useState({});
    const navigate = useNavigate();

    const [star, setStar] = useState([
        { value: 1, filled: "0" },
        { value: 2, filled: "0" },
        { value: 3, filled: "0" },
        { value: 4, filled: "0" },
        { value: 5, filled: "0" }
    ])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/public/getTopDrivers`);
                // const response = await axios.get(`http://localhost:8082/api/public/getTopDrivers`);
                console.log(response, "response")
                setData(response?.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

    }, []);

    useEffect(() => {
        if (data) {
            data?.topDriver?.map((item) => {
                if (item.overAllRating) {
                    for (let i = 0; i < Number(item.overAllRating); i++) {
                        const newStar = star?.map((item) => ({
                            ...item,
                            filled: "1"
                        }))
                        setStar(newStar)
                    }
                }
            })
        }
    }, [data]);

    // translation handling
    const { t } = useTranslation();
    const { home } = t("screen");


    return (
        <>

            <div className='row justify-center overflow-x-hidden'>
                <div className='col-lg-11'>

                    <section className="ms-fun-brand ms-bg-4  pb-90 pt-20" id='popularEvent'>
                        <div className="container">
                            {/* <div className="row justify-content-center bdFadeUp">
                        <div className="col-xl-7">
                            <div className="section__title-wrapper mb-65 text-center bd-title-anim">
                                <span className="section__subtitle">Top Driver</span>
                            </div>
                        </div>
                    </div> */}
                            <div className="row justify-content-center bdFadeUp">
                                <div className="col-xl-7">
                                    <div className="section__title-wrapper mb-65 text-center bd-title-anim">
                                        <span className="section__subtitle" style={{ fontFamily: 'Oregano-Italic, sans-serif',textTransform:"none" }}>{home?.topDriver}</span>
                                        {/* <h2 className="section__title">
                                    The Most <span className="animated-underline active"> popular Drivers</span>
                                    This Month
                                </h2> */}
                                        <h2 className="section__title" style={{ fontFamily: 'Prompt-Regular, sans-serif',textTransform:"none" }}>
                                            {home?.theMostPopularDriversThisMonth}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="ms-fun-brand-wrap2 bdFadeUp" >
                                {data?.topDriver?.map((item) => {

                                    return (

                                        <Link to="/offer-ride" className="ms-fun-brand-item ms-fun-border" key={item._id} >
                                            <div className="ms-fun-brand-top mb-3">
                                                <div className="ms-fun-brand-thumb">

                                                    {/*  */}
                                                    <img loading='lazy' style={{ width: '100%', height: "100%" }} src={item?.profileImage} alt="function brand" />

                                                </div>
                                                <div className="ms-fun-brand-content">
                                                    <h3 className="ms-fun-brand-title">
                                                        <Link href="#">{item.firstName + " " + item?.lastName}</Link>
                                                    </h3>
                                                </div>
                                            </div>

                                            <div className="ms-fun-brand-bottom">


                                                <div style={{ display: "flex" }}>

                                                    <span style={{ display: "flex" }}>
                                                        <div style={{ display: "flex", gap: "5px" }}>
                                                            {Array.from({ length: Math.round(Number(item?.overAllRating)) }).map((_, index) => (
                                                                <FaStar key={index} size={30} color="orange" />
                                                            ))}
                                                            {
                                                                Array.from({ length: 5 - Math.round(Number(item?.overAllRating)) }).map((_, index) => (
                                                                    <CiStar key={index} size={30} color="orange" />

                                                                ))
                                                            }
                                                        </div>

                                                    </span>
                                                    <span style={{ marginTop: "-2px", marginLeft: "0.3em", fontSize: "24px", color: "white" }}>{item.overAllRating}</span>
                                                </div>
                                            </div>
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>
                    </section>

                </div>
            </div>

        </>
    );
};

export default TopDrivers;
