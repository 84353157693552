



import { lazy, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';







// const HomeMainArea = lazy(() => import("./components/Home/HomeMainArea"))

// const EventDetails = lazy(() => import("./components/event-details/EventDetailsMainArea"))
// const OfferARide = lazy(() => import("./components/rides/OfferARide"))
// const Event = lazy(() => import("./components/rides/BookARide"))
// const HeaderOne = lazy(() => import("./layout/header/HeaderOne"))
// const FooterOne = lazy(() => import("./layout/footer/FooterOne"))
// const BookARide = lazy(() => import("./components/rides/BookARide"))
// const BookAEvent = lazy(() => import("./components/rides/BookAEvent"))
// const CreateEvent = lazy(() => import("./components/event-details/CreateEvent"))
// const BecomeOrganiser = lazy(() => import("./components/becomeOrganiser/becomeOrganiser"))
// const TermsAndCondition = lazy(() => import("./components/termsAndCondition/termsAndCondition"))
// const Privacy = lazy(() => import("./components/privacy/privacy"))
// const Refund = lazy(() => import("./components/Refund/refundPolicy"))
// const AboutUS = lazy(() => import("./components/aboutUs/aboutUs"))
// const NotFound = lazy(() => import("./components/NotFound/notFound"))
 
import PreLoander from './components/preloander/PreLoander';
import HomeMainArea from './components/Home/HomeMainArea';
import Wrapper from "./layout/DefaultWrapper"
import ContactUs from "./components/contact/ContactMainArea"
import EventDetails from "./components/event-details/EventDetailsMainArea";



import OfferARide from './components/rides/OfferARide';
import Event from './components/rides/BookARide';
import HeaderOne from './layout/header/HeaderOne';
import FooterOne from './layout/footer/FooterOne';
import BookARide from './components/rides/BookARide';
import BookAEvent from './components/rides/BookAEvent';
import CreateEvent from './components/event-details/CreateEvent';
import BecomeOrganiser from "./components/becomeOrganiser/becomeOrganiser"
import TermsAndCondition from './components/termsAndCondition/termsAndCondition';
import Privacy from './components/privacy/privacy';
import Refund from './components/Refund/refundPolicy';
import AboutUS from './components/aboutUs/aboutUs';
import NotFound from './components/NotFound/notFound';


// preloader


function App() {

  useEffect(() => {
    const loadingScreen = document.getElementById('loading-screen');
    if (loadingScreen) {
        loadingScreen.style.display = 'none';
    }
}, []);


  return (
    <>

      {/* <Wrapper> */}
      <HeaderOne />
      <div className="ms-all-content ms-content-mobile-space">
        <Routes>
          <Route path='/' element={<PreLoander />}>


            <Route path="/" element={<HomeMainArea />} />
            {/* <Route path="/contact-us" element={<ContactUs />} /> */}
            <Route path="/become-organisers" element={<BecomeOrganiser />} />
            <Route path="/terms-condition" element={<TermsAndCondition />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/refund-policy" element={<Refund />} />
            <Route path="/event-details" element={<EventDetails />} />
            <Route path="/offer-ride" element={<OfferARide />} />
            <Route path="/book-ride" element={<BookARide />} />
            <Route path="/book-event" element={<BookAEvent />} />
            <Route path="/offer-ride-event" element={<Event />} />
            {/* <Route path="/create-event" element={<CreateEvent />} /> */}
            <Route path="/about-us" element={<AboutUS />} />
            {/* <Route path="/offer-ride" element={<OfferARide />} />
            <Route path="/offer-ride" element={<OfferARide />} /> */}
            <Route path="*" element={<NotFound />} /> {/* 404 route */}


          </Route>


        </Routes>

        <FooterOne />

      </div>

      {/* </Wrapper> */}
      {/* <BackToTop /> */}
      {/* <Footer /> */}
    </>
  );
}

export default App;
