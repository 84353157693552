// import { Spinner } from "@material-tailwind/react";
import React from "react";
import "./loander.css"


const Loader = () => {
    return (
        <div id="loading-screen">
            <div class="center">

                <div class="loader l1"></div>
                <div class="loader l2"></div>
                <div class="loader l3"></div>
                <div class="loader l4"></div>
                <div class="loader l5"></div>
                <div class="loader l6"></div>
                <div class="loader l7"></div>
                <div class="loader l8"></div>
                <div class="loader l9"></div>
                <div class="loader l10"></div>


            </div>


        </div>
    );
};

export default Loader;
