import React from 'react'
import bannerTitleBg2 from '../../assets/img/banner/title-bg.jpg';
// import girls from "../../assets/img/logo/girl.png";
import girls from "../../assets/img/images/Keep Alive Your Passions.png"
// import money from "../../assets/img/logo/money.png";
import money from "../../assets/img/images/Save Money, Save Time and Reduce Pollution.png"
// import mobile from "../../assets/img/images/Expand Your Network (1).png"
import mobile from "../../assets/img/images/Expand Your Network(2).png"
// import driver from "../../assets/img/logo/driver.png";
import driver from "../../assets/img/images/Reliable Driver(4).png"
import { useTranslation } from 'react-i18next';

const WhyChooseUs = () => {
    const { t } = useTranslation();
    const { home } = t("screen");

    return (
        <section className="pb-50 pt-50" id='features'>
            <div>
                <div className="section__title-wrapper mb-65 text-center bd-title-anim mb-25">
                    <h2 className="section__title" style={{textTransform:"none",fontFamily: 'Prompt-Regular, sans-serif'}}>
                        {home?.whyStageDriving}
                    </h2>
                </div>
                <div className="pt-3 pb-0">
                    <div className="ms-fun-brand-wrap2 pt-2">
                        <div className='flex justify-center items-center' style={{ flexDirection: "column" }}>
                            <div style={{ height: '200px', display: 'flex', alignItems: 'center' }}>
                                <img className='why_chose_img' src={mobile} alt="" />
                            </div>
                            <div style={{ height: '100px', display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                <p className="section__subtitle mt-4" style={{ fontSize: "26px", fontWeight: "bold",textTransform:"none" }}>
                                    {home?.expandYourNetwork}
                                </p>
                            </div>
                        </div>
                        <div className='flex justify-center items-center' style={{ flexDirection: "column" }}>
                            <div style={{ height: '200px', display: 'flex', alignItems: 'center' }}>
                                <img src={girls} alt="" className="why_chose_img" />
                            </div>
                            <div style={{ height: '100px', display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                <p className="section__subtitle mt-4" style={{ fontSize: "26px", fontWeight: "bold",textTransform:"none" }}>
                                    {home?.Enjoyevents}
                                </p>
                            </div>
                        </div>
                        <div className='flex justify-center items-center' style={{ flexDirection: "column" }}>
                            <div style={{ height: '200px', display: 'flex', alignItems: 'center' }}>
                                <img src={driver} alt="" className="why_chose_img" />
                            </div>
                            <div style={{ height: '100px', display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                <p className="section__subtitle mt-4" style={{ fontSize: "26px", fontWeight: "bold",textTransform:"none" }}>
                                    {home?.Sharethetrip}
                                </p>
                            </div>
                        </div>
                        <div className='flex justify-center items-center' style={{ flexDirection: "column" }}>
                            <div style={{ height: '200px', display: 'flex', alignItems: 'center' }}>
                                <img src={money} alt="" className="why_chose_img" />
                            </div>
                            <div style={{ height: '100px', display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                <p className="section__subtitle mt-4" style={{ fontSize: "26px", fontWeight: "bold",textTransform:"none" }}>
                                    {home?.saveMoneySaveTime}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhyChooseUs;
