import React from 'react';
import WorkBgImg from "../../assets/img/bg/work-bg.jpg";
// import thumb1 from '../../assets/img/work/work-thumb-01.png';
import thumb1 from '../../assets/img/logo/ilust1.jpg';
// import thumb2 from '../../assets/img/work/work-thumb-02.png';
import thumb2 from '../../assets/img/logo/ilust2.jpg';
// import thumb3 from '../../assets/img/work/work-thumb-03.png';
import thumb3 from '../../assets/img/logo/ilust5.jpg';
import vectorShape from '../../assets/img/work/vactoe-shape.png';
import workStarImg from '../../assets/img/work/star.png';
import workArrowImg from '../../assets/img/work/arrow.png';
import { imageLoader } from '../../hooks/ImageLoader';
import WorkFutureSvgIconOne from '../../svg/WorkFutureIconOne';
import WorkFutureSvgIconTwo from '../../svg/WorkFutureSvgIconTwo';
import WorkFutureSvgIconThree from '../../svg/WorkFutureSvgIconThree';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MdOutlineImageSearch } from "react-icons/md";
import { FaCar } from "react-icons/fa6";
import { MdAirlineSeatReclineExtra } from "react-icons/md";
import { FaPeopleLine } from "react-icons/fa6";

import searchForEvent from "../../assets/img/images/Search for an Event(3).png"
import bookASeat from "../../assets/img/images/Book a Seat(2).png"
import expandNetwork from "../../assets/img/images/Expand your Network (H)(2).png"
import offerARide from "../../assets/img/images/Offer a Ride.png"





const HowItWorks = () => {


    // translation handling
    const { t } = useTranslation();
    const { home } = t("screen");



    const work_future_data = [
        {
            id: 1,
            icon: <MdOutlineImageSearch className='h-8 w-8' />,
            // icon: <img className='h-8 w-8' src={searchForEvent} alt="" />,
            title: home?.SearchForAnEvent,
            description: home?.Seethemaporfilterbydate
        },
        {
            id: 2,
            icon: <FaCar className='h-8 w-8' />,
            title: home?.Offeraride,
            description: home?.Planthetriptoleadthegrouptotheevent
        },
        {
            id: 3,
            icon: <MdAirlineSeatReclineExtra className='h-8 w-8' />,
            title: home?.Bookaseat,
            description: home?.Gototheeventandcomebackhomewithyourfavoritedriver
        },

        {
            id: 4,
            icon: <FaPeopleLine className='h-8 w-8' />,
            title: home?.Expandyournetwork,
            description: home?.followotherusersforthenextexperience
        },
    ];
    const navigate = useNavigate();





    return (

        <section className="work__area work-overlay pt-100 pb-32 include__bg" style={{ backgroundImage: `url(${WorkBgImg.src})` }} id='howItWorks'>
            <img className="work__vactor-shape d-none d-xl-block" loading='lazy' style={{ width: 'auto', height: "auto" }} src={vectorShape} alt="vactoe-shape.png" />
            <div className="container">
                <div className="row justify-content-end bdFadeUp">
                    <div className="col-xl-6">
                        {/* <div className="section__title-wrapper mb-65 text-center bd-title-anim">
                            <span className="section__subtitle" style={{ fontFamily: 'Oregano-Italic, sans-serif' }} >{home?.howTtWorks}</span>
                            
                            <h2 className="section__title two " style={{ fontFamily: 'Prompt-Regular, sans-serif' }}  >
                                {home?.welcomeToStageDrivingEventsRidesAtOnePlace}
                            </h2>
                        </div> */}

                        <div className="section__title-wrapper mb-50 bd-title-anim">
                            <span className="section__subtitle" style={{ fontFamily: 'Oregano-Italic, sans-serif' }} >{home?.howTtWorks}</span>
                            <h2 className="section__title two " style={{ fontFamily: 'Prompt-Regular, sans-serif' , textTransform:"none" }}  >
                                {home?.EventsRidesAtOnePlace}
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-around sm: gap-10 bdFadeUp">
                    <div className="col-xl-5 col-md-12  flex justify-center items-center ">
                        <div className="work__thumb-wrapper d-inline-block p-relative  ">
                            <div className="work__thumb-inner">
                                {/* <div className="work__thumb">
                                    <img loading='lazy' width={347} height={456} src={thumb1} alt="work image" />
                                </div> */}
                                 <div className="work__small-thumb ">
                                    <div className="work__thumb ">
                                        <img loading='lazy' style={{ width: '100%', height: "auto" }} width={264} height={202} src={searchForEvent} alt="work image" />
                                    </div>
                                    <div className="work__thumb ">
                                        <img loading='lazy' style={{ width: '100%', height: "auto" }} src={offerARide} alt="work image" />
                                    </div>
                                </div>
                                <div className="work__small-thumb">
                                    <div className="work__thumb">
                                        <img loading='lazy' style={{ width: '100%', height: "auto" }} width={264} height={202} src={bookASeat} alt="work image" />
                                    </div>
                                    <div className="work__thumb">
                                        <img loading='lazy' style={{ width: '100%', height: "auto" }} src={expandNetwork} alt="work image" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-5 col-md-12 flex   items-center ">
                        <div className=" ">

                            <div className="work__features-inner">
                                {work_future_data.map((item) => (
                                    <div className="work__features-item" key={item.id}>
                                        <div className="work__features-icon">
                                            <span>{item.icon}</span>
                                        </div>
                                        <div className="work__features-content">
                                            <h4 style={{ fontFamily: 'Prompt-Regular, sans-serif' , textTransform:"none" }} >{item.title}</h4>
                                            <p style={{textTransform:"none"}}>{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                                <div className="work__features-bottom">
                                    <div className="work__features-action">
                                        <div className="work__features-btn" onClick={() => navigate("become-organisers")}>
                                            <Link className="unfill__btn feature-unfill_btn" href="/become-organisers">{home?.createEvent}</Link>
                                        </div>
                                        <div className="features__btn-text">

                                            <span>{home?.Getstartedwithyourfirstevent}</span>
                                        </div>
                                    </div>
                                    <div className="work__features-arrow sm:block hidden">
                                        <img loading='lazy' style={{ width: '100%', height: "auto" }} src={workArrowImg} alt="image not found" />
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </section>

    );
};

export default HowItWorks;
