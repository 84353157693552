import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import { imageLoader } from "../../hooks/ImageLoader";
// import NewsImg from '../../assets/img/news/news-07.jpg';
import ContactForm from "../../form/contact-form";
import ContactMapArea from "../contact/ContactMapArea";
import PartnerAreaMain from "../common/PartnerArea/PartnerAreaMain";
import { Link } from "react-router-dom";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMail } from "react-icons/md";

import useScrollToTop from "../../hooks/UseScrollToTop";
import OrganiserForm from "../../form/organiser-form";
import orgImg from "../../assets/img/logo/becomeOrg.jpg"
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import Loader from "../preloander/Loander";


import { FaArrowRight } from "react-icons/fa6";
// import login from "../../assets/img/logo/login.jpg";
import login from "../../assets/img/images/Access to the admin panel(2).png"

// import publish from "../../assets/img/logo/publish.jpg";
// import publish from "../../assets/img/images/Analyze the statistics.png"
import publish from "../../assets/img/images/Analyze the statistics(2).png"
// import form from "../../assets/img/logo/form.jpg";
// import form from "../../assets/img/images/Create an event(2).png"
import form from "../../assets/img/images/Create an event(3).png"


import request from "../../assets/img/logo/request.jpg"





const ContactMainArea = () => {

    const formRef = useRef(null);

    const { t } = useTranslation();

    const { becomeOrganiser, createEvent } = t("screen");

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000);

    }, [])

    useScrollToTop();

    const scrollToForm = () => {
        if (formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.log('Form reference is not available.');
        }
    };


    return (
        <>

            {
                loading ?

                    <Loader /> :

                    <div>

                        <Breadcrumb title={becomeOrganiser?.howtoCreateanEvent} />
                        <section className="header" >
                            <div className="container-fluid d-flex" style={{ padding: "0%" }}>
                                <div style={{ width: "100%" }}>


                                    <div>

                                        <div
                                            className=' h-30'
                                        >
                                            <div>


                                                <div className="container">

                                                    {/* <div className="row align-items-center ">

                                                        <div className="col-xl-6 col-md-12 d-flex justify-content-center" >
                                                            <div className='p-2' style={{ color: "white", gap: "4px", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "self-start" }}>

                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <h2 style={{ fontSize: "2em", marginBottom: "5px", fontFamily: 'Prompt-Regular, sans-serif' }} className='text-white'>{createEvent?.Accesstotheadminpanel}</h2>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <FaArrowRight style={{ marginTop: "4px", width: "30px", }} />
                                                                    <p style={{ fontFamily: 'Prompt-Regular, sans-serif' }} className='text-white'>{createEvent?.Createyourprofilefillingintheform}</p>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <FaArrowRight style={{ marginTop: "4px", width: "30px", }} />
                                                                    <p style={{ fontFamily: 'Prompt-Regular, sans-serif' }} className='text-white'>{createEvent?.loginwiththeuseridandpwapproved}</p>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <FaArrowRight style={{ marginTop: "4px", width: "30px", }} />
                                                                    <p style={{ fontFamily: 'Prompt-Regular, sans-serif' }} className='text-white'>{createEvent?.edittheeventlocationdetails}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className=" py-5 col-xl-6 col-md-12 d-flex  justify-content-center img-container">

                                                            <img src={login} alt="" style={{ borderRadius: "12px" }} />

                                                        </div>
                                                    </div> */}


                                                    {/* <div className="row d-flex flex-lg-row flex-column-reverse ">

                                                        <div className=" py-5 col-xl-6 col-md-12 d-flex  justify-content-center img-container">

                                                            <img src={form} alt="" style={{ borderRadius: "12px" }} />

                                                        </div>
                                                        <div className="col-xl-6 col-md-12 d-flex align-items-center justify-content-center" >
                                                            <div className='p-2' style={{ color: "white", gap: "4px", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "self-start" }}>

                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <h2 style={{ fontSize: "2em", marginBottom: "5px", fontFamily: 'Prompt-Regular, sans-serif' }} className='text-white'>{createEvent?.Createanevent}</h2>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <FaArrowRight style={{ marginTop: "4px", width: "30px", }} />
                                                                    <p style={{ fontFamily: 'Prompt-Regular, sans-serif' }} className='text-white'>{createEvent?.Edittheeventdetails}</p>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <FaArrowRight style={{ marginTop: "4px", width: "30px", }} />
                                                                    <p style={{ fontFamily: 'Prompt-Regular, sans-serif' }} className='text-white'>{createEvent?.addanydescriptionandanyfacilityrestriction}</p>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <FaArrowRight style={{ marginTop: "4px", width: "30px", }} />
                                                                    <p style={{ fontFamily: 'Prompt-Regular, sans-serif' }} className='text-white'>{createEvent?.addthelinktotheplaylistandtobuytheticket}</p>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <FaArrowRight style={{ marginTop: "4px", width: "30px", }} />
                                                                    <p style={{ fontFamily: 'Prompt-Regular, sans-serif' }} className='text-white'>{createEvent?.addoneormoreimages}</p>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <FaArrowRight style={{ marginTop: "4px", width: "30px", }} />
                                                                    <p style={{ fontFamily: 'Prompt-Regular, sans-serif' }} className='text-white'>{createEvent?.publishorsaveasdraft}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div> */}


                                                    {/* <div className="row align-items-center ">
                                                        <div className="col-xl-6 col-md-12 d-flex justify-content-center" >
                                                            <div className='p-2' style={{ color: "white", gap: "4px", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "self-start" }}>

                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <h2 style={{ fontSize: "2em", marginBottom: "5px", fontFamily: 'Prompt-Regular, sans-serif' }} className='text-white'>{createEvent?.Analyzethestatistics}</h2>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <FaArrowRight style={{ marginTop: "4px", width: "30px", }} />
                                                                    <p style={{ fontFamily: 'Prompt-Regular, sans-serif' }} className='text-white'>{createEvent?.reviewyoureventslist}</p>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "3px", flexDirection: "row", alignItems: "flex-start" }}>
                                                                    <FaArrowRight style={{ marginTop: "4px", width: "30px", }} />
                                                                    <p className='text-white'>{createEvent?.tracktheperformancesbyevent}</p>
                                                                </div>
                                                               
                                                            </div>
                                                        </div>
                                                        <div className=" py-5 col-xl-6 col-md-12 d-flex  justify-content-center img-container">
                                                            <img src={publish} alt="" style={{ borderRadius: "12px" }} />
                                                        </div>
                                                    </div> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container ">

                                        {/* 1st */}
                                        <div className="grid md:grid-cols-2 grid-cols-1 justify-around items-center ">
                                            {/* Image Container */}
                                            <div className="py-5 md:w-auto w-full d-flex justify-center img-container">
                                                <img src={login} alt="Search Ride" className="rounded-xl" />
                                            </div>

                                            {/* Text Content */}
                                            <div className="md:w-auto w-full d-flex justify-center">
                                                <div className="p-2 text-white flex flex-col gap-1 justify-start items-start">
                                                    <div className="flex gap-1 items-start">
                                                        <h2 className="section__subtitle text-3xl mb-1 font-sans text-white" style={{ fontFamily: 'Oregano-Italic, sans-serif', textTransform:"none" }}>
                                                            {createEvent?.Accesstotheadminpanel}
                                                        </h2>
                                                    </div>

                                                    <div className="flex gap-1 items-start">
                                                    <span className=" mr-2 font-bold text-lg">1.</span>
                                                        <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                            {createEvent?.Createyourprofilefillingintheform} <span  onClick={scrollToForm} className="cursor-pointer text-blue-700 underline">{createEvent?.form}</span>
                                                        </p>
                                                    </div>

                                                    <div className="flex gap-1 items-start">
                                                    <span className=" mr-2 font-bold text-lg">2.</span>
                                                        <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                            {createEvent?.loginwiththeuseridandpwapproved}
                                                        </p>
                                                    </div>

                                                    <div className="flex gap-1 items-start">
                                                        {/* <FaArrowRight className="mt-1 mr-2" /> */}
                                                        <span className=" mr-2 font-bold text-lg">3.</span>
                                                        <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                            {createEvent?.edittheeventlocationdetails}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        {/* 2st */}
                                        <div className="grid md:grid-cols-2 grid-cols-1  items-center ">
                                            <div className=" md:hidden py-5 w-full flex justify-center img-container">
                                                <img src={form} alt="Route Detail" className="rounded-xl" />
                                            </div>
                                            {/* Text Content */}
                                            <div className=" hidden w-full md:flex justify-center">
                                                <div className="p-2 text-white flex flex-col gap-1 justify-start items-start">
                                                    <div className="flex gap-1 items-start">
                                                        <h2 className="section__subtitle text-3xl mb-1 font-sans text-white" style={{ fontFamily: 'Oregano-Italic, sans-serif', textTransform:"none" }}>
                                                            {createEvent?.Createanevent}
                                                        </h2>
                                                    </div>

                                                    <div className="flex gap-1 items-start">
                                                        {/* <FaArrowRight className="mt-1 mr-2" /> */}
                                                        <span className=" mr-2 font-bold text-lg">1.</span>
                                                        <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                            {createEvent?.Edittheeventdetails}
                                                        </p>
                                                    </div>

                                                    {/* <div className="flex gap-1 items-start">
                                                        <FaArrowRight className="mt-1 mr-2" />
                                                        <span className=" mr-2 font-bold text-lg">2.</span>
                                                        <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                            {createEvent?.addanydescriptionandanyfacilityrestriction}
                                                        </p>
                                                    </div> */}

                                                    <div className="flex gap-1 items-start">
                                                        {/* <FaArrowRight className="mt-1 mr-2" /> */}
                                                        <span className=" mr-2 font-bold text-lg">2.</span>
                                                        <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                            {createEvent?.addthelinktotheplaylistandtobuytheticket}
                                                        </p>
                                                    </div>

                                                    {/* <div className="flex gap-1 items-start">
                                                        <FaArrowRight className="mt-1 mr-2" />
                                                        <span className=" mr-2 font-bold text-lg">4.</span>
                                                        <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                            {createEvent?.addoneormoreimages}
                                                        </p>
                                                    </div> */}

                                                    <div className="flex gap-1 items-start">
                                                        {/* <FaArrowRight className="mt-1 mr-2" /> */}
                                                        <span className=" mr-2 font-bold text-lg">3.</span>
                                                        <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                            {createEvent?.publishorsaveasdraft}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Image Container */}
                                            <div className=" py-5 w-full hidden md:flex justify-center img-container">
                                                <img src={form} alt="Route Detail" className="rounded-xl" />
                                            </div>

                                            <div className=" md:hidden w-full flex justify-center">
                                                <div className="p-2 text-white flex flex-col gap-1 justify-start items-start">
                                                    <div className="flex gap-1 items-start">
                                                        <h2 className="section__subtitle text-3xl mb-1 font-sans text-white" style={{ fontFamily: 'Oregano-Italic, sans-serif', textTransform:"none" }}>
                                                            {createEvent?.Createanevent}
                                                        </h2>
                                                    </div>

                                                    <div className="flex gap-1 items-start">
                                                        {/* <FaArrowRight className="mt-1 mr-2" /> */}
                                                        <span className=" mr-2 font-bold text-lg">1.</span>
                                                        <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                            {createEvent?.Edittheeventdetails}
                                                        </p>
                                                    </div>

                                                    <div className="flex gap-1 items-start">
                                                        {/* <FaArrowRight className="mt-1 mr-2" /> */}
                                                        <span className=" mr-2 font-bold text-lg">2.</span>
                                                        <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                            {createEvent?.addanydescriptionandanyfacilityrestriction}
                                                        </p>
                                                    </div>

                                                    <div className="flex gap-1 items-start">
                                                        {/* <FaArrowRight className="mt-1 mr-2" /> */}
                                                        <span className=" mr-2 font-bold text-lg">3.</span>
                                                        <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                            {createEvent?.addthelinktotheplaylistandtobuytheticket}
                                                        </p>
                                                    </div>

                                                    <div className="flex gap-1 items-start">
                                                        {/* <FaArrowRight className="mt-1 mr-2" /> */}
                                                        <span className=" mr-2 font-bold text-lg">4.</span>
                                                        <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                            {createEvent?.addoneormoreimages}
                                                        </p>
                                                    </div>

                                                    <div className="flex gap-1 items-start">
                                                        {/* <FaArrowRight className="mt-1 mr-2" /> */}
                                                        <span className=" mr-2 font-bold text-lg">5.</span>
                                                        <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                            {createEvent?.publishorsaveasdraft}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* 3rd */}
                                        <div className="grid md:grid-cols-2 grid-cols-1 justify-around items-center ">
                                            {/* Image Container */}
                                            <div className="py-5 md:w-auto w-full d-flex justify-center img-container">
                                                <img src={publish} alt="Search Ride" className="rounded-xl" />
                                            </div>

                                            {/* Text Content */}
                                            <div className="md:w-auto w-full d-flex justify-center">
                                                <div className="p-2 text-white flex flex-col gap-1 justify-start items-start">

                                                    <div className="flex gap-1 items-start">
                                                        <h2 className="section__subtitle text-3xl mb-1 font-sans text-white" style={{ fontFamily: 'Oregano-Italic, sans-serif', textTransform:"none" }}>
                                                        {createEvent?.Analyzethestatistics}
                                                        </h2>
                                                    </div>

                                                    <div className="flex gap-1 items-start">
                                                        {/* <FaArrowRight className="mt-1 mr-2" /> */}
                                                        <span className=" mr-2 font-bold text-lg">1.</span>
                                                        <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                        {createEvent?.reviewyoureventslist}
                                                        </p>
                                                    </div>

                                                    <div className="flex gap-1 items-start">
                                                        {/* <FaArrowRight className="mt-1 mr-2" /> */}
                                                        <span className=" mr-2 font-bold text-lg">2.</span>
                                                        <p className="font-sans" style={{ fontFamily: 'Prompt-Regular, sans-serif', textTransform:"none" }}>
                                                        {createEvent?.tracktheperformancesbyevent}
                                                        </p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="ms-contact-area pb-1 pt-50" ref={formRef} id="form">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-8">
                                        <div className="ms-contact-wrap ms-contact-space mb-70 ms-bg-2">
                                            <OrganiserForm />
                                        </div>
                                    </div>
                                    <div className="col-xl-4">
                                        <div className="ms-contact-img-wrap mb-70 d-inline-block p-relative">
                                            <div className="ms-contact-img m-img ms-br-15 fix p-relative zindex-1">
                                                <div className="ms-overlay ms-overlay11"></div>
                                                {/* */}
                                                <img src={orgImg} placeholder="blur" loading='lazy' width={356} height={433}
                                                    alt="org image" />
                                            </div>
                                            <div className="ms-contact-content text-center">
                                                <div className="ms-round-icon mx-auto " style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    {/* <i className="flaticon-phone-call"></i> */}
                                                    <MdOutlineMail />
                                                </div>
                                                <h3 style={{ fontFamily: 'Prompt-Regular, sans-serif' }} className="ms-title3 ms-text2 mb-15">Contact us</h3>
                                                <p style={{ color: "white", textTransform: "lowercase" }}><a href="mailto:contact@stagedriving.com" style={{textTransform:"none"}}>contact@stagedriving.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
            }

            {/* <ContactMapArea /> */}
            {/* <PartnerAreaMain spacingClass="pt-130" /> */}
        </>
    )
};

export default ContactMainArea;