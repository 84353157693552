import React from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
// import menu_data from '../../data/menu-data';
import "../../assets/scss/components/_menu.scss";
import { useTranslation } from 'react-i18next';



const Menus = () => {

    // translation handling
    const { t } = useTranslation();
    const { home } = t("screen");


    const menu_data = [
        {
            id: 1,
            title: home?.home,
            link: "/",
        },

       

        // {
        //     id: 3,
        //     title: home?.createEvent1,
        //     link: "/create-event",
        // },
        {
            id: 4,
            title: home?.bookARide1,
            link: "/book-ride",
        },

        {
            id: 5,
            title: home?.offerARide,
            link: "/offer-ride",
        },
        // {
        //     id: 7,
        //     title: "Features",
        //     data: "#features",
        // },
        // {
        //     id: 6,
        //     title: "Contact Us",
        //     link: "/contact-us",
        // },
        {
            id: 7,
            title: home?.becomeOrganiser,
            link: "/become-organisers",
        },
        {
            id: 2,
            title: home?.aboutUs,
            link: "/about-us"
        },
    ];




    const location = useLocation();
    const navigate = useNavigate();
    const pathName = location.pathname;

    

    const handleScrollLinkClick = (data) => {
        if (pathName !== '/') {
            navigate('/', { state: { scrollTo: data.replace('#', '') } });
        } else {
            scroll.scrollTo(document.getElementById(data.replace('#', '')).offsetTop - 70);
        }
    };

    return (
        <>
            {menu_data.map((menu, i) => {

                console.log("menu",menu);
                

                return (
                    <li key={i} className={` ${menu.active ? 'active has-dropdown' : ''} 
                        ${menu.megaMenu ? 'has-dropdown has-mega-menu' : ''} 
                        ${menu.hasDropdown ? 'has-dropdown' : ''}`} >

                        {menu.link ? (
                            <RouterLink style={{textTransform:"none"}} className={`${pathName == menu.link ? "link" : ""}  `} to={menu.link}>
                               {menu.title}
                            </RouterLink>
                        ) : (
                            <div
                                className={`${pathName === menu.data ? "link" : "link"}`}
                                onClick={() => handleScrollLinkClick(menu.data)}
                                style={{ cursor: 'pointer' }}
                            >
                                {menu.title}
                            </div>
                        )}

                        {menu.submenus && (
                            <ul className="submenu">
                                {menu.submenus.map((sub, i) => (
                                    <li key={i}>
                                        <RouterLink to={sub.link}>
                                            {sub.title}
                                        </RouterLink>
                                    </li>
                                ))}
                            </ul>
                        )}
                        {menu.mega_menus && (
                            <ul className="mega-menu">
                                {menu.mega_menus.map((mega, i) => (
                                    <li key={i}>
                                        <RouterLink to={mega.link} className="mega-menu-title">
                                            {mega.title}
                                        </RouterLink>
                                        <ul>
                                            {mega.submenus.map((sub_mega, i) => (
                                                <li key={i}>
                                                    <RouterLink to={sub_mega.link}>{sub_mega.title}</RouterLink>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                );
            })}
        </>
    );
};

export default Menus;
