// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import "./style/index.scss"
// if (typeof window !== "undefined") {
//   require("bootstrap/dist/js/bootstrap")
// };
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from 'react-router-dom';
// import AppProvider from "./contextApi/AppProvider"
// import { ToastContainer } from 'react-toastify';
// import UseMousePointer from "./utils/MouseCursorUtilis"
// import { DirectionProvider } from "./hooks/useDirection"
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <BrowserRouter>
//     <UseMousePointer />
//     <React.StrictMode>
//       <AppProvider>
//         <DirectionProvider>
//           <ToastContainer>
//             <App />

//           </ToastContainer>
//         </DirectionProvider>
//       </AppProvider>
//     </React.StrictMode>
//   </BrowserRouter>

// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// import React from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter } from 'react-router-dom';
// import { ToastContainer } from 'react-toastify';
// if (typeof window !== "undefined") {
//   require("bootstrap/dist/js/bootstrap")
// };
// import './index.css';
// import "./style/index.scss"
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import AppProvider from "./contextApi/AppProvider"
// import UseMousePointer from "./utils/MouseCursorUtilis"
// import { DirectionProvider } from "./hooks/useDirection"
// import { createRoot } from 'react-dom/client';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import "bootstrap/dist/js/bootstrap"; // Moved to the top
import './index.css';
import "./style/index.scss"
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppProvider from "./contextApi/AppProvider"
import UseMousePointer from "./utils/MouseCursorUtilis"
import { DirectionProvider } from "./hooks/useDirection"
import { createRoot } from 'react-dom/client';
import "./i18n"

const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />

    <UseMousePointer />
    <React.StrictMode>
      <AppProvider>
        <DirectionProvider>
          <ToastContainer>
          </ToastContainer>
        </DirectionProvider>
      </AppProvider>
    </React.StrictMode>
  </BrowserRouter>,
  // document.getElementById('root')
);

reportWebVitals();
