import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import ErrorMsg from './error-msg';
import { contact_schema } from '../utils/validation-schema';
import { Country, State, City } from "country-state-city";
import axios from 'axios';
import { useTranslation } from 'react-i18next';


const OrganiserForm = () => {

    const { t } = useTranslation();
    const { becomeOrganiser,organiserForm } = t("screen");

    const [isChecked, setIsChecked] = useState(true);

    const [countryData, setCountryData] = useState({
        countryList: "",
        countryName: "",
        countryISOCode: "",
        CountryISDCode: "",
        stateList: "",
        stateName: "",
        stateISOCode: "",
        cityList: "",
        cityName: "",
    });
    const {
        countryList,
        countryName,
        countryISOCode,
        CountryISDCode,
        stateList,
        stateName,
        stateISOCode,
        cityList,
        cityName,
    } = countryData;


    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
    });

    async function handleChange(e) {

        const { name, value } = e.target;

        if (name == "firstName") {

            setFormData((prev) => ({
                ...prev,
                firstName: value
            }))

        } else if (name == "lastName") {

            setFormData((prev) => ({
                ...prev,
                lastName: value
            }))

        } else if (name == "email") {

            setFormData((prev) => ({
                ...prev,
                email: value
            }))

        } else if (name == "phone") {

            setFormData((prev) => ({
                ...prev,
                phone: value
            }))

        }

    }


    // ------ Handling the Country Name & ISOCode & ISDCode
    const handleCountry = (e) => {
        const { name, value } = e.target;
        const selectedCountry = countryList.find(
            (country) => country?.name === value
        );
       
        if (selectedCountry) {
            setCountryData((prev) => ({
                ...prev,
                countryName: selectedCountry?.name,
                countryISOCode: selectedCountry?.isoCode,
                CountryISDCode: selectedCountry?.phonecode,
            }));
        }
        setCountryData((prev) => ({
            ...prev,
            countryName: value,
        }));
    };


    const handleState = (e) => {
        const { name, value } = e.target;
        const selectedState = stateList.find((state) => state?.name === value);
       
        if (selectedState) {
            setCountryData((prev) => ({
                ...prev,
                stateName: selectedState?.name,
                stateISOCode: selectedState?.isoCode,
            }));
        }
    };


    // ----- Handling the city name as per the state name
    const handleCity = (e) => {
        const { name, value } = e.target;
        setCountryData((prev) => ({
            ...prev,
            cityName: value,
        }));
    };


    useEffect(() => {
        setCountryData((prev) => ({
            ...prev,
            countryList: Country.getAllCountries(),
            stateList: State.getStatesOfCountry(countryISOCode),
            cityList: City.getCitiesOfState(countryISOCode, stateISOCode),
        }));
    }, [countryISOCode, stateISOCode]);

    async function handleSubmit(e) {

        e.preventDefault()

        try {

            let errorCount = 0;

            if(formData?.email == "" ||  formData?.phone == "" || formData?.firstName == "" || formData?.lastName == "" || cityName == ""  || stateISOCode == "" || countryISOCode == "" ){

                errorCount += 1;

            }

            if(errorCount == 0){

                const dataObject = {
                    email: formData?.email,
                    phone: formData?.phone,
                    firstName: formData?.firstName,
                    lastName: formData?.lastName,
                    city: cityName,
                    state: stateISOCode,
                    country: countryISOCode,
                }
    
                console.log("dataObject",dataObject);

                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/api/public/requestForOrganiser`,
                    {
                       ...dataObject
                    },
                );

                toast.success(response?.data.message);

                setCountryData({
                    countryList: "",
                    countryName: "",
                    countryISOCode: "",
                    CountryISDCode: "",
                    stateList: "",
                    stateName: "",
                    stateISOCode: "",
                    cityList: "",
                    cityName: "",
                });

                setFormData({
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                })

            }else {
                toast.warning("Please Fill All Fields.")
            }


        } catch (error) {

            console.log("error while requesting.");
            toast.error(error?.response?.data?.message)

        }

    }

    return (
        <>
            <form >
                <div className="row">
                    <div className="col-md-6">
                        <div className="ms-input2-box mb-30">
                            <input
                                type="text"
                                placeholder={organiserForm.firstname}
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                required
                            />
                            {/* {touched.firstName && <ErrorMsg error={errors.name} />} */}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="ms-input2-box mb-30">
                            <input
                                type="text"
                                placeholder={organiserForm.lastname}
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                required
                            />
                            {/* {touched.lastName && <ErrorMsg error={errors.name} />} */}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="ms-input2-box mb-30">
                            <input
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                type="email"
                                id="email"
                                required
                                placeholder={organiserForm.email}
                            />
                            {/* {touched.email && <ErrorMsg error={errors.email} />} */}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="ms-input2-box mb-30">
                            <input
                                type="text"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                placeholder={organiserForm.phonenumber}
                                required
                            />
                            {/* {touched.number && <ErrorMsg error={errors.number} />} */}
                        </div>
                    </div>


                    <div className="col-md-6">
                        <div className="ms-input2-box mb-30">
                            <select
                                name="country"
                                value={countryName}
                                onChange={(e) => handleCountry(e)}
                                style={{ padding: "0px 30px", background: "#0d0d0d", width: "100%", border: "1px solid #border", height: "65px", borderRadius: "32.5px" }}
                            // disabled={isViewed}
                            >
                                <option>{organiserForm.country}</option>
                                {countryList &&
                                    countryList?.map((country) => (
                                        <option key={country?.isoCode}>
                                            {country && country?.name}
                                        </option>
                                    ))}
                            </select>
                            {/* {touched.massage && <ErrorMsg error={errors.massage} />} */}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="ms-input2-box mb-30">
                            <select
                                name="state"
                                value={stateName}
                                onChange={(e) => handleState(e)}
                                // disabled={isViewed}
                                style={{ padding: "0px 30px", background: "#0d0d0d", width: "100%", border: "1px solid #border", height: "65px", borderRadius: "32.5px" }}

                            >
                                <option value="">{organiserForm.State}</option>

                                {stateList &&
                                    stateList?.map((state) => (
                                        <option key={state?.isoCode}>
                                            {state && state?.name}
                                        </option>
                                    ))}
                            </select>
                            {/* {touched.massage && <ErrorMsg error={errors.massage} />} */}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="ms-input2-box mb-30">
                            <select
                                name="city"
                                value={cityName}
                                onChange={(e) => handleCity(e)}
                                style={{ padding: "0px 30px", background: "#0d0d0d", width: "100%", border: "1px solid #border", height: "65px", borderRadius: "32.5px" }}

                            // disabled={isViewed}
                            // className="form-control py-2  appearance-none relative flex-1"
                            >
                                <option value="">{organiserForm.City}</option>

                                {cityList &&
                                    cityList?.map((city) => (
                                        <option key={city?.name}>
                                            {city && city?.name}
                                        </option>
                                    ))}
                            </select>


                            {/* {touched.massage && <ErrorMsg error={errors.massage} />} */}
                        </div>
                    </div>



                    <div className="col-md-12">
                        {/* <div className="ms-checkbox">
                            <span>Save my name, email, and website in this browser for the next time I comment.</span>
                            <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={() => setIsChecked(!isChecked)}
                            />
                            <span className="checkmark"></span>
                        </div> */}
                        <div className="ms-submit-btn mt-10">
                            <button onClick={handleSubmit} className="unfill__btn" >{becomeOrganiser?.requestNow}</button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default OrganiserForm;
