import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FcLike } from "react-icons/fc";
// import popular_band_fuction_data from '../../data/popular-band-function-data';
import GetRatting from '../../hooks/GetRating';
import { imageLoader } from '../../hooks/ImageLoader';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FaLocationDot } from "react-icons/fa6";
import { PhotoProvider } from 'react-photo-view';
import { useTranslation } from 'react-i18next';




import { SwiperSlide, Swiper } from 'swiper/react';
import { Autoplay } from 'swiper/modules'
import 'swiper/css/bundle';
import image1 from "../../assets/img/logo/becomeOrg.jpg"
import image2 from "../../assets/img/logo/becomeOrg.jpg"
import image3 from "../../assets/img/logo/becomeOrg.jpg"
import image4 from "../../assets/img/logo/becomeOrg.jpg"


const MostPopularEvent2 = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/public/listPopularEvents`);
                // const newData = response?.data?.events.length > 4 ? response.data?.events?.splice(0,4) : response?.data;
                // console.log("newData",newData);

                const events = response?.data?.events;

                let newEvents = [];

                if (events?.length > 4) {

                    const cutEvent = events?.splice(0, 4);
                    newEvents = cutEvent;

                } else {
                    newEvents = events
                }

                // console.log("events",events);


                setData(newEvents);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const handleEvents = (item) => {
        const id = item._id;
        navigate("event-details", { state: { id } });
        console.log("clicked", id)
    };

    function textShortner(text) {

        let result = "";

        if (text?.length > 18) {

            result = `${text.slice(0, 18)}...`;

        } else {
            result = text
        }

        return result

    }

    // translation handling
    const { t } = useTranslation();
    const { home } = t("screen");

    return (
        <>
            <div className='row justify-center overflow-x-hidden'>
                <div className='col-lg-11'>
                    <section className="ms-popular__area pt-100  fix">
                        <div className="container">
                            <div className="row align-items-end mb-25 bdFadeUp">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="section__title-wrapper mb-14 bd-title-anim">
                                        <span className="section__subtitle" style={{ fontFamily: 'Oregano-Italic, sans-serif',textTransform:"none" }}>{home?.eventThisMonth}</span>
                                        <h2 className="section__title" style={{ fontFamily: 'Prompt-Regular, sans-serif',textTransform:"none" }}>{home?.theMostPopularEventThisMonth}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row bdFadeUp">
                                <div className="col-xxl-12">
                                    <div className="tab-content" >
                                        <div className="tab-pane fade show active"
                                        >
                                            <div className="ms-popular-active fix">
                                                <div>
                                                    <Swiper
                                                        modules={[Autoplay]}
                                                        loop={false}
                                                        spaceBetween={25}
                                                        autoplay={{
                                                            delay: 0
                                                        }}
                                                        speed={6000}
                                                        observeParents={true}
                                                        observer={true}
                                                        breakpoints={{
                                                            1200: {
                                                                slidesPerView: 4
                                                            },
                                                            992: {
                                                                slidesPerView: 3
                                                            },
                                                            768: {
                                                                slidesPerView: 2
                                                            },
                                                            576: {
                                                                slidesPerView: 2
                                                            },
                                                            0: {
                                                                slidesPerView: 1
                                                            },
                                                        }}
                                                    >

                                                        {data && data?.map((item, index) => {
                                                            const imgArray = JSON.parse(item?.eventImage);
                                                            const firstImgCheck = imgArray?.[0];
                                                            let firstImg = firstImgCheck ? firstImgCheck : "assets/img/function-brand/function-brand-01.png";

                                                            return (
                                                                <SwiperSlide key={index}>
                                                                    <div className="ms-popular__item p-relative mb-30" onClick={() => handleEvents(item)}>
                                                                        <div className="ms-popular__thumb responsiveWAndH ">
                                                                            <div className="ms-popular-overlay"></div>
                                                                            <Link href={`/genres-details/${item.id}`}>
                                                                                <img className='' placeholder="blur" loading='lazy' src={firstImg}
                                                                                    alt="popular band" /></Link>
                                                                            <Link to="" className="ms-popular__link">
                                                                                <span className="ms-popular-icon"><i
                                                                                    className="fa-regular fa-arrow-right-long"></i></span>
                                                                            </Link>
                                                                        </div>
                                                                        <h4 style={{ fontFamily: 'Prompt-Regular, sans-serif' }} className="ms-popular__title"><Link to="">
                                                                            {textShortner(item.eventName)}
                                                                        </Link></h4>
                                                                    </div>
                                                                </SwiperSlide>
                                                            );
                                                        })}
                                                    </Swiper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section >
                </div>

            </div>
        </>
    );
};

export default MostPopularEvent2;
